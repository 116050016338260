import { GenerateReelSet } from "../../../utils";
import { Machine } from "../machine";
import { ReelConfig } from "./ReelConfig";
import { REEL_DIRECTION } from "./ReelDirection";
import { REEL_EVENTS } from "./ReelEvents";

export abstract class Reel extends Phaser.GameObjects.Container {
    static reelIndex = 0;
    container: Phaser.GameObjects.Container = null;
    index = 0;
    direction: REEL_DIRECTION = REEL_DIRECTION.TOP_TO_BOTTOM;
    shuffleSet = true;
    hold = false;
    speed = 1;
    currentPos = 0;
    stopPos = -1;
    maskObj: Phaser.GameObjects.Graphics | Phaser.GameObjects.Shape | Phaser.GameObjects.Image = null;
    startTweens: Phaser.Tweens.Tween[] = [];
    stopTweens: Phaser.Tweens.Tween[] = [];

    protected _size = 3;
    protected _column = 0;
    protected _cellWidth = 100;
    protected _cellHeight = 100;
    protected _symbols: (string | number)[] = [];
    protected _reelSymbols: any[] = [];
    protected _currentReelSet: (string | number)[] = [];
    protected _reelSet: (string | number)[] = [];
    protected _useMask = false;
    protected _maskConfig: {
        type: "bitmap" | "geometry";
        config: Phaser.Types.GameObjects.Graphics.Options & { points?: any[] | Phaser.Geom.Point[]; } & Phaser.Types.GameObjects.Sprite.SpriteConfig;
    };
    protected _maskGameObjectData: Phaser.Display.Masks.BitmapMask | Phaser.Display.Masks.GeometryMask = null;
    protected _excess = 0;
    protected _showExcess = false;
    protected _reverseOrder = false;
    protected _startTweenVars: {
        duration: number;
        delay: number;
        ease: string | ((...args) => number);
        easeParams: any[];
    } = {
            duration: 1000,
            delay: 0,
            ease: Phaser.Math.Easing.Back.In,
            easeParams: [1.7]
        };
    protected _loopTweenVars: {
        duration: number;
        ease: string | ((...args) => number);
        easeParams: any[];
    } = {
            duration: 100,
            ease: Phaser.Math.Easing.Linear,
            easeParams: null
        };
    protected _stopTweenVars: {
        duration: number;
        delay: number;
        ease: string | ((...args) => number);
        easeParams: any[];
    } = {
            duration: 1000,
            delay: 0,
            ease: Phaser.Math.Easing.Back.Out,
            easeParams: [1.7]
        };

    protected _isRunning = false;
    protected _isPreStarted = false;
    protected _isPostStarted = false;
    protected _isLooping = false;
    protected _loopCount = 0;
    protected _isStoppable = false;
    protected _isPreStoppped = false;
    protected _isPostStopped = false;

    constructor(scene: Phaser.Scene, public machine: Machine, config: ReelConfig = {}) {
        super(scene, 0, 0, config.children);
        // build reel other config
        config.add = false;
        Phaser.GameObjects.BuildGameObject(scene, this, config);
        this.setConfig(config);
        this.boot();
    }
    resetConfig(): void {
        // reset reel configs
        this.releaseSymbols();
        this._isRunning = false;
        this._isStoppable = false;
        this._isPreStarted = false;
        this._isPostStarted = false;
        this._isLooping = false;
        this._loopCount = 0;
        this._isPreStoppped = false;
        this._isPostStopped = false;
        this.index = 0;
        this._size = 3;
        this._column = 0;
        this.speed = 1;
        this._cellWidth = 100;
        this._cellHeight = 100;
        this._symbols = [];
        this._reelSet = [];
        this._currentReelSet = [];

        this.direction = REEL_DIRECTION.TOP_TO_BOTTOM;
        this.shuffleSet = true;
        this._useMask = false;
        this._maskConfig = null;
        this._excess = 0;
        this._showExcess = false;
        this._reverseOrder = false;
        this.hold = false;
        this._startTweenVars = {
            duration: 1000,
            delay: 0,
            ease: Phaser.Math.Easing.Back.In,
            easeParams: [1.7]
        };
        this._loopTweenVars = {
            duration: 100,
            ease: Phaser.Math.Easing.Linear,
            easeParams: null
        };
        this._stopTweenVars = {
            duration: 1000,
            delay: 0,
            ease: Phaser.Math.Easing.Back.Out,
            easeParams: [1.7]
        };

        if (this.container !== null) {
            this.remove(this.container);
            this.container.destroy(true);
            this.container = null;
        }
    }
    setConfig(config: ReelConfig): void {
        // reset all properties
        this.resetConfig();

        // set reel configs
        this.index = Phaser.Utils.Objects.GetFastValue(config, "index", Reel.reelIndex++);
        this._size = Phaser.Utils.Objects.GetFastValue(config, "size", 3);
        this._column = Phaser.Utils.Objects.GetFastValue(config, "column", 0);
        this.speed = Phaser.Utils.Objects.GetFastValue(config, "speed", 1);
        this._cellWidth = Phaser.Utils.Objects.GetFastValue(config, "cellWidth", 100);
        this._cellHeight = Phaser.Utils.Objects.GetFastValue(config, "cellHeight", this._cellWidth);
        this._symbols = Phaser.Utils.Objects.GetFastValue(config, "symbols", []);

        let reelSet = Phaser.Utils.Objects.GetFastValue(config, "reelSet", 50);
        if (typeof reelSet === "number") {
            reelSet = GenerateReelSet([].concat(this.machine.symbolTypes), reelSet, reelSet);
        }

        this._reelSet = reelSet;

        this.direction = Phaser.Utils.Objects.GetFastValue(config, "direction", REEL_DIRECTION.TOP_TO_BOTTOM);
        this.shuffleSet = Phaser.Utils.Objects.GetFastValue(config, "shuffle", true);
        let useMask = Phaser.Utils.Objects.GetFastValue(config, "useMask", false);
        this._useMask = typeof useMask === "boolean" ? useMask : true;
        if (typeof useMask === "object") {
            const maskType = Phaser.Utils.Objects.GetFastValue(useMask, "type", "geometry");
            const maskConfig = Phaser.Utils.Objects.GetFastValue(useMask, "config", {});
            this._maskConfig = {
                type: maskType,
                config: maskConfig
            };
        }

        this._showExcess = Phaser.Utils.Objects.GetFastValue(config, "showExcess", false);
        this._reverseOrder = Phaser.Utils.Objects.GetFastValue(config, "reverseOrder", false);
        this.hold = Phaser.Utils.Objects.GetFastValue(config, "hold", false);

        // set start tween effect properties
        this._startTweenVars.duration = Phaser.Utils.Objects.GetValue(config, "startTween.duration", 1000);
        this._startTweenVars.delay = Phaser.Utils.Objects.GetValue(config, "startTween.delay", 0);
        this._startTweenVars.ease = Phaser.Utils.Objects.GetValue(config, "startTween.ease", Phaser.Math.Easing.Back.In);
        this._startTweenVars.easeParams = Phaser.Utils.Objects.GetValue(config, "startTween.easeParams", [4]);

        // set loop tween effect properties
        this._loopTweenVars.duration = Phaser.Utils.Objects.GetValue(config, "loopTween.duration", 100);
        this._loopTweenVars.ease = Phaser.Utils.Objects.GetValue(config, "loopTween.ease", Phaser.Math.Easing.Linear);
        this._loopTweenVars.easeParams = Phaser.Utils.Objects.GetValue(config, "loopTween.easeParams", null);

        // set start tween effect properties
        this._stopTweenVars.duration = Phaser.Utils.Objects.GetValue(config, "stopTween.duration", 1000);
        this._stopTweenVars.delay = Phaser.Utils.Objects.GetValue(config, "stopTween.delay", 0);
        this._stopTweenVars.ease = Phaser.Utils.Objects.GetValue(config, "stopTween.ease", Phaser.Math.Easing.Back.Out);
        this._stopTweenVars.easeParams = Phaser.Utils.Objects.GetValue(config, "stopTween.easeParams", [4]);
    }
    boot(): void {
        // create symbols container
        this.container = this.scene.add.container();
        this.container.name = "Symbols";
        this.add(this.container);

        this._reelSymbols = [];

        // fill container with symbols
        this.fillSymbols();

        // create mask
        this.setReelMask(this._useMask);
    }
    releaseSymbol(symbol: any): void {
        // remove from symbols array
        const index = this._reelSymbols.indexOf(symbol);
        if (index > -1) {
            Phaser.Utils.Array.RemoveAt(this._reelSymbols, index);
        }

        // remove symbol from container
        this.container.remove(symbol, false);
        this.machine.releaseSymbol(symbol, this);
    }
    releaseSymbols(): void {
        const children = this.container?.getAll() ?? [];
        children.forEach(this.releaseSymbol, this);

        this.container?.removeAll();
        this._reelSymbols = [];
    }
    resetCurrentReelSet(): void {
        this._currentReelSet = [].concat(this._symbols).concat(this.shuffleSet ? Phaser.Utils.Array.Shuffle(this._reelSet) : this._reelSet);
    }
    setSymbols(symbols: (string | number)[]): void {
        this._symbols = symbols;

        this.resetCurrentReelSet();
    }
    fillSymbols(): void {
        // release all symbols
        this.releaseSymbols();

        // reset current reelset
        this.resetCurrentReelSet();

        // create all symbols with exceess symbols
        const len = this._size + this._excess;
        const reelLen = this._currentReelSet.length;
        const start = this._excess * -1;
        const symbols = [];

        for (let i = start; i < len; i++) {
            let sym = this._currentReelSet[(reelLen + i) % reelLen];
            const symbol = this.machine.getSymbol(sym, this, i);

            if (symbol) {
                sym = sym === null ? "null" : sym;
                symbol.name = `SYMBOL[${sym.toString().toUpperCase()}]`;
                symbol.setData("identity", sym);
                symbol.setData("index", this.index);
                symbol.setData("row", i);
                symbol.setData("column", this.column);
                symbols.push(symbol);
            }
        }

        // align symbols
        this.alignSymbols(symbols);
        // add symbols to container
        this.container.add(symbols);

        // fill with symbol game object
        this._reelSymbols = [].concat(...symbols);

        // refresh symbol depth
        this.orderSymbols();

        // refresh excess symbols
        this.showExcessSymbols(this._isRunning);

        this.emit(REEL_EVENTS.FILLED);
    }
    alignSymbols(symbols: any[]) {
        if (this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.BOTTOM_TO_TOP) {
            let startY = this._cellHeight * this._excess * -1;

            symbols.forEach((s) => {
                s.y += startY;
                startY += this._cellHeight;
            }, this);
        } else {
            let startX = this._cellWidth * this._excess * -1;

            symbols.forEach((s) => {
                s.x += startX;
                startX += this._cellWidth;
            }, this);
        }
    }
    orderSymbols() {
        if (this._isRunning === false) {
            const symbols = this.container.getAll<any>();
            const len = symbols.length;

            for (var i = 0; i < len; i++) {
                symbols[i].setDepth(this._reverseOrder === true ? (len - 1) - i : i);
            }
            this.container.sort("depth");
        }
    }
    setReelMask(useMask: boolean): this {
        const hasMask = this.container.mask !== null;
        this.showReelMask(false, true);

        // destroy old mask game object
        if (this.maskObj !== null) {
            this.maskObj.destroy(true);
            this.maskObj = null;
            this._maskGameObjectData = null;
        }
        this._useMask = useMask;

        if (this._useMask === true) {
            const wtm = this.container.getWorldTransformMatrix();
            const isVertical = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.BOTTOM_TO_TOP;
            const x = isVertical ? this._maskConfig?.config?.x ?? wtm.tx : this._maskConfig?.config?.x ?? wtm.tx + ((this._cellWidth * this._excess) * this.container.scaleX);
            const y = isVertical ? this._maskConfig?.config?.y ?? wtm.ty + ((this._cellHeight * this._excess) * this.container.scaleY) : this._maskConfig?.config?.y ?? wtm.ty;

            if (this._maskConfig?.type === "bitmap") {
                this.maskObj = this.scene.make.image({ ...this._maskConfig.config, x, y }, false);
                this._maskGameObjectData = new Phaser.Display.Masks.BitmapMask(this.scene, this.maskObj);
            }
            else {

                let points = this._maskConfig?.config?.points ?? null;

                const width = isVertical ? this.container.getBounds().width : (this._cellWidth * this._size) * this.container.scaleX;
                const height = isVertical ? (this._cellHeight * this._size) * this.container.scaleY : this.container.getBounds().height;

                // fill points if it is undefined
                if (points === null) {
                    points = [
                        { x: 0, y: 0 },
                        { x: isVertical ? width : 0, y: isVertical ? 0 : height },
                        { x: width, y: height },
                        { x: isVertical ? 0 : width, y: isVertical ? height : 0 },
                        { x: 0, y: 0 }
                    ];
                }

                const fillStyle = this._maskConfig?.config?.fillStyle ?? {};
                // set properties if not
                if (typeof fillStyle.color !== "number") {
                    fillStyle.color = 0xffffff;
                }
                if (typeof fillStyle.alpha !== "number") {
                    fillStyle.alpha = 1;
                }

                this.maskObj = this.scene.make.graphics({ ...this._maskConfig, fillStyle }, false);
                this.maskObj.fillPoints(points);
                this.maskObj.setPosition(x, y);
                this._maskGameObjectData = new Phaser.Display.Masks.GeometryMask(this.scene, this.maskObj as Phaser.GameObjects.Graphics);
                /*this.maskObj.visible = false;
                this.scene.sys.displayList.add(this.maskObj);*/
            }
        }

        this.showReelMask(hasMask);

        return this;
    }
    showReelMask(value: boolean, hard = false): void {
        if (this._useMask === true && value === true) {
            this.container?.setMask(this._maskGameObjectData);
        } else {
            this.container?.clearMask(hard);
        }
    }
    protected changeSymbolLocation() {
        const ttbOrltr = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.LEFT_TO_RIGHT;
        this.currentPos = ttbOrltr ? this.currentPos - 1 : this.currentPos + 1;
        this.currentPos = this.currentPos >= this._currentReelSet.length ? 0 : (this.currentPos < 0 ? this._currentReelSet.length - 1 : this.currentPos);

        // release symbol
        this.releaseSymbol((ttbOrltr ? this._reelSymbols[this._reelSymbols.length - 1] : this._reelSymbols[0]));
        const sym = this._currentReelSet[this.currentPos];
        const row = ttbOrltr ? -this._excess : (this.size + (this._excess * 2));
        const symbol = this.machine.getSymbol(sym, this, row);

        if (symbol) {
            symbol.name = `SYMBOL[${sym.toString().toUpperCase()}]`;
            symbol.setData("identity", sym);
            symbol.setData("index", this.index);
            symbol.setData("row", row);
            symbol.setData("column", this.column);
            if (ttbOrltr) {
                this.container.addAt(symbol, 0);
                Phaser.Utils.Array.AddAt(this._reelSymbols, symbol, 0);
            }
            else {
                this.container.add(symbol);
                Phaser.Utils.Array.Add(this._reelSymbols, symbol);
            }
        }

        // align symbols
        this.alignSymbols(this._reelSymbols);
    }
    showExcessSymbols(value: boolean): void {
        for (let sIndex = 0; sIndex < this._excess; sIndex++) {
            const element = this._reelSymbols[sIndex];
            if (element) {
                element.visible = this._showExcess ? true : value;
            }
        }
        for (let sIndex = this._excess + this._size; sIndex < this._size + this._excess * 2; sIndex++) {
            const element = this._reelSymbols[sIndex];
            if (element) {
                element.visible = this._showExcess ? true : value;
            }
        }
    }
    abstract start(): void;
    abstract stop(): void;
    cascade(
        insert: { [index: string]: string | number },
        dropTween?: Phaser.Types.Tweens.TweenBuilderConfig,
        insertTween?: Phaser.Types.Tweens.TweenBuilderConfig
    ) {
        const ttbOrltr = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.LEFT_TO_RIGHT;
        const isVertical = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.BOTTOM_TO_TOP;
        let tempSymbols = [...this._symbols];
        const remainSymbols = [];
        const addedSymbols = [];
        const removeIndexes = Object.keys(insert);
        let emptyCount = ttbOrltr ? removeIndexes.length : 0;
        const symbolObjs = this.symbolObjects;
        const symbolGroups = [];
        let symbolGroup: { sym: any[], count: number } = { sym: [], count: -1 };
        removeIndexes.sort();

        tempSymbols.forEach((sym, index) => {
            if (removeIndexes.indexOf(index.toString()) > -1) {
                addedSymbols.push(insert[index]);
                emptyCount += (ttbOrltr ? -1 : 1);
                if (symbolGroup.sym.length > 0) {
                    symbolGroups.push(symbolGroup);
                    symbolGroup = { sym: [], count: -1 };
                }
            } else {
                remainSymbols.push(sym);
                symbolGroup.sym.push(symbolObjs[index]);
                symbolGroup.count = emptyCount;
            }
        }, this);
        if (symbolGroup.sym.length > 0) {
            symbolGroups.push(symbolGroup);
        }

        if (ttbOrltr) {
            tempSymbols = addedSymbols.concat(...remainSymbols);
        } else {
            tempSymbols = remainSymbols.concat(addedSymbols);
        }

        // add drop tweens
        const cascadeTweens: Phaser.Types.Tweens.TweenBuilderConfig[] = [];
        if (!dropTween) {
            dropTween = {
                targets: [],
                duration: 250
            }
        }
        if (!dropTween.duration) {
            dropTween.duration = 150;
        }
        symbolGroups.forEach((group) => {
            const groupTween: Phaser.Types.Tweens.TweenBuilderConfig = { targets: group.sym, duration: group.count * dropTween.duration };
            if (isVertical) {
                groupTween.y = `${ttbOrltr ? '+' : '-'}=${this._cellHeight * group.count}`;
            } else {
                groupTween.x = `${ttbOrltr ? '+' : '-'}=${this._cellWidth * group.count}`;
            }
            cascadeTweens.push(Object.assign({}, dropTween, groupTween));
        }, this);

        // added symbols' group
        symbolGroup = { sym: [], count: removeIndexes.length };
        const symLen = tempSymbols.length;
        addedSymbols.forEach((sym, index) => {
            const row = ttbOrltr ? index : symLen - (symbolGroup.count + index);
            const symbol = this.machine.getSymbol(sym, this, row);
            if (symbol) {
                this.container.add(symbol);
                if (isVertical) {
                    symbol.y += ttbOrltr ? ((symbolGroup.count - index) * this._cellHeight * -1) : (symLen + index) * this._cellHeight;
                } else {
                    symbol.x += ttbOrltr ? ((symbolGroup.count - index) * this._cellWidth * -1) : (symLen + index) * this._cellWidth;
                }
                symbol.name = `SYMBOL[${sym.toString().toUpperCase()}]`;
                symbol.setData("identity", sym);
                symbol.setData("index", this.index);
                symbol.setData("row", row);
                symbol.setData("column", this.column);

                symbolGroup.sym.push(symbol);
            }
        }, this);

        if (symbolGroup.sym.length > 0) {
            symbolGroups.push(symbolGroup);
            if (!insertTween) {
                insertTween = {
                    targets: symbolGroup.sym,
                    duration: 250
                }
            }
            if (!insertTween.duration) {
                insertTween.duration = 150;
            }
            const onComplete = insertTween.onComplete ? insertTween.onComplete : null;
            const scope = insertTween.callbackScope ? insertTween.callbackScope : null;
            const groupTween: Phaser.Types.Tweens.TweenBuilderConfig = {
                targets: symbolGroup.sym,
                duration: symbolGroup.count * insertTween.duration,
                onComplete: (tween: Phaser.Tweens.Tween, targets: any | any[], ...param: any[]) => {
                    this.setSymbols(tempSymbols);
                    this.fillSymbols();
                    this.showExcessSymbols(false);
                    this.showReelMask(false);
                    this.emit(REEL_EVENTS.CASCADE_STOPPED, this, this.symbols);
                    if (onComplete) {
                        onComplete.call(scope, tween, targets, ...param);
                    }
                },
                callbackScope: this
            };
            if (isVertical) {
                groupTween.y = `${ttbOrltr ? '+' : '-'}=${this._cellHeight * symbolGroup.count}`;
            } else {
                groupTween.x = `${ttbOrltr ? '+' : '-'}=${this._cellWidth * symbolGroup.count}`;
            }
            cascadeTweens.push(Object.assign({}, insertTween, groupTween));
        }

        this.showExcessSymbols(false);
        this.showReelMask(true);
        this.emit(REEL_EVENTS.CASCADE_STARTED, this, addedSymbols, remainSymbols, tempSymbols);
        this.scene.tweens.addMultiple(cascadeTweens);
    }
    // overwrite
    destroy(fromScene?: boolean): void {
        this.resetConfig();
        this.removeAllListeners();
        super.destroy(fromScene);
    }
    // getters and setters
    get size(): number {
        return this._size;
    }
    set size(value: number) {
        if (this._size !== value) {
            this._size = value;
            this.fillSymbols();
        }
    }
    get column(): number {
        return this._column;
    }
    get symbols(): (string | number)[] {
        return this._symbols;
    }
    set symbols(value: (string | number)[]) {
        this.setSymbols(value);
        this.fillSymbols();
    }
    get symbolObjects(): any[] {
        return this._excess === 0 ? this._reelSymbols.slice(0) : this._reelSymbols.slice(this._excess, this._excess * -1);
    }
    get reverseOrder(): boolean {
        return this._reverseOrder;
    }
    set reverseOrder(value: boolean) {
        if (this._reverseOrder !== value) {
            this._reverseOrder = value;
            this.orderSymbols();
        }
    }
    get isRunning(): boolean {
        return this._isRunning;
    }
    set isRunning(value: boolean) {
        this._isRunning = value;
        this.emit(REEL_EVENTS.RUNNING, this, value);
    }
    get isPreStarted(): boolean {
        return this._isPreStarted;
    }
    set isPreStarted(value: boolean) {
        this._isPreStarted = value;
        this.emit(REEL_EVENTS.PRE_START, this, value);
    }
    get isPostStarted(): boolean {
        return this._isPostStarted;
    }
    set isPostStarted(value: boolean) {
        this._isPostStarted = value;
        this.emit(REEL_EVENTS.POST_START, this, value);
    }
    get isLooping(): boolean {
        return this._isLooping;
    }
    set isLooping(value: boolean) {
        this._isLooping = value;
        this.emit(REEL_EVENTS.LOOPING, this, value, this._loopCount);
    }
    get isPreStoppped(): boolean {
        return this._isPreStoppped;
    }
    set isPreStoppped(value: boolean) {
        this._isPreStoppped = value;
        this.emit(REEL_EVENTS.PRE_STOP, this, value);
    }
    get isPostStopped(): boolean {
        return this._isPostStopped;
    }
    set isPostStopped(value: boolean) {
        this._isPostStopped = value;
        this.emit(REEL_EVENTS.POST_STOP, this, value);
    }
    get isStoppable(): boolean {
        return this._isStoppable;
    }
    set isStoppable(value: boolean) {
        this._isStoppable = value;
        this.emit(REEL_EVENTS.STOPPABLE, this, value);
    }
}