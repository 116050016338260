import { Reel } from "./Reel";
import { ReelConfig } from "./ReelConfig";
import { REEL_DIRECTION } from "./ReelDirection";

export class DropReel extends Reel {
    setConfig(config: ReelConfig): void {
        super.setConfig(config);
        this._excess = 0;
    }
    start(): void {
        this.isRunning = true;
        this.isPreStarted = false;
        this.isPostStarted = false;
        if (this.hold) {
            this.isPreStarted = true;
            this.isPostStarted = true;
        }
        this.isLooping = false;
        this._loopCount = 0;
        this.isPreStoppped = false;
        this.isPostStopped = false;
        this.isStoppable = false;

        const ttbOrltr = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.LEFT_TO_RIGHT;
        const isVertical = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.BOTTOM_TO_TOP;
        const width = this.direction === REEL_DIRECTION.LEFT_TO_RIGHT ? this._cellWidth * this._size : this._cellWidth * -this._size;
        const height = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM ? this._cellHeight * this._size : this._cellHeight * -this._size;
        const onStartIndex = ttbOrltr ? this._reelSymbols.length - 1 : 0;
        const onStopIndex = ttbOrltr ? 0 : this._reelSymbols.length - 1;
        const unitDuration = this._startTweenVars.duration / (isVertical ? this._cellHeight : this._cellWidth);

        this._reelSymbols.forEach((symbol, index) => {
            const delayMultiplier = ttbOrltr ? this._reelSymbols.length - 1 - index : index;
            const totalDelay = this._startTweenVars.delay * delayMultiplier;
            const stopX = width + (this._cellWidth * index);
            const stopY = height + (this._cellHeight * index);
            const tweenConfig: Phaser.Types.Tweens.TweenBuilderConfig = {
                targets: symbol,
                delay: this.machine.isForcedStop ? 0 : totalDelay,
                ease: this._startTweenVars.ease,
                easeParams: this._startTweenVars.easeParams,
                callbackScope: this,
                onComplete: () => {
                    this.machine.releaseSymbol(symbol, this);
                }
            };

            if (isVertical) {
                tweenConfig.y = stopY;
                tweenConfig.duration = unitDuration * (Math.round(Math.abs(symbol.y - stopY) * 100) / 100);
            } else {
                tweenConfig.x = stopX;
                tweenConfig.duration = unitDuration * (Math.round(Math.abs(symbol.x - stopX) * 100) / 100);
            }

            if (index === onStartIndex) {
                tweenConfig.onStart = () => {
                    this.isPreStarted = true;
                    this.showReelMask(true);
                    this.showExcessSymbols(true);
                };
            }
            if (index === onStopIndex) {
                tweenConfig.onComplete = () => {
                    this.isPostStarted = true;
                    this.startTweens = [];
                    this.machine.releaseSymbol(symbol, this);
                };
            }

            this.startTweens.push(this.scene.tweens.add(tweenConfig));
        });
    }
    stop(): void {
        if (this.hold) {
            this.isPreStoppped = true;
            this.isPostStopped = true;
            this.isStoppable = true;
            this.isRunning = false;
        } else {
            this.isStoppable = true;
            this.startTweens.forEach((t) => {
                t.stop();
            }, this);
            this.stopDrop();
        }
    }
    stopDrop() {
        if (this.isStoppable) {
            this.fillSymbols();

            const ttbOrltr = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.LEFT_TO_RIGHT;
            const isVertical = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.BOTTOM_TO_TOP;
            const totalWidth = this._cellWidth * (this._size + 1);
            const totalHeight = this._cellHeight * (this._size + 1);
            const unitDuration = this._stopTweenVars.duration / (isVertical ? this._cellHeight : this._cellWidth);
            let width = this.direction === REEL_DIRECTION.LEFT_TO_RIGHT ? totalWidth * -1 : totalWidth;
            let height = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM ? totalHeight * -1 : totalHeight;
            let onStartIndex = ttbOrltr ? this._reelSymbols.length - 1 : 0;
            let onStopIndex = ttbOrltr ? 0 : this._reelSymbols.length - 1;

            this._reelSymbols.forEach((symbol, index) => {
                const delayMultiplier = ttbOrltr ? this._reelSymbols.length - 1 - index : index;
                const stopX = symbol.x;
                const stopY = symbol.y;
                const totalDelay = this._stopTweenVars.delay * delayMultiplier;

                const tweenConfig: Phaser.Types.Tweens.TweenBuilderConfig = {
                    targets: symbol,
                    delay: this.machine.isForcedStop ? 0 : totalDelay,
                    ease: this._stopTweenVars.ease,
                    easeParams: this._stopTweenVars.easeParams,
                    callbackScope: this
                };

                if (isVertical) {
                    symbol.y = height + (this._cellHeight * index);
                    tweenConfig.duration = unitDuration * (Math.round(Math.abs(symbol.y - stopY) * 100) / 100);
                    tweenConfig.y = stopY;
                } else {
                    symbol.x = width + (this._cellWidth * index);
                    tweenConfig.duration = unitDuration * (Math.round(Math.abs(symbol.x - stopX) * 100) / 100);
                    tweenConfig.x = stopX;
                }

                if (index === onStartIndex) {
                    tweenConfig.onStart = () => {
                        this.isPreStoppped = true;
                        this.showExcessSymbols(false);
                        //this.container.visible = true;
                    };
                }
                if (index === onStopIndex) {
                    tweenConfig.onComplete = () => {
                        this.fillSymbols();
                        this.showExcessSymbols(false);
                        this.showReelMask(false);
                        this.isPostStopped = true;
                        this.isRunning = false;
                        this.stopTweens = [];
                    };
                }

                this.stopTweens.push(this.scene.tweens.add(tweenConfig));
            }, this);
        }
    }
}