export enum REEL_EVENTS {
    RUNNING = "running",
    PRE_START = "prestart",
    POST_START = "poststart",
    LOOPING = "looping",
    PRE_STOP = "prestop",
    POST_STOP = "poststop",
    STOPPABLE = "stoppable",
    CASCADE_STARTED = "cascadestarted",
    CASCADE_STOPPED = "cascadestopped",
    FILLED = "filled"
}