import { EVENTS } from "./Events";
import { Game } from "./Game";
import { Level } from "./Level";
import { GameObjectBuilderConfig } from "./utils";

export class Scene extends Phaser.Scene {
    game: Game;
    level: Level;
    constructor(config?: string | Phaser.Types.Scenes.SettingsConfig & { level?: { [gameObjectName: string]: GameObjectBuilderConfig } | Phaser.Types.Loader.FileConfig }) {
        super(config);
        if (typeof config !== "string") {
            this.level = new Level(this, config?.level || {});
        }
    }
    create(data?: any) {
        this.game.events.on("gamesizechanged", this.onGameSizeChanged, this);
        this.game.refreshSize();
        this.sys.events.on(Phaser.Scenes.Events.RESUME, this.onGameSizeChanged, this);
        this.sys.events.on(Phaser.Scenes.Events.WAKE, this.onGameSizeChanged, this);
        const d = data;
        this.level.once("boot", () => {
            d.level = this.level.detail;
            if (typeof (this as any).ready === "function") {
                this.game.events.emit("sceneready", this.sys.settings.key);
                (this as any).ready(d);
            }
        }, this);
        this.level.boot();

        this.events.once(Phaser.Scenes.Events.DESTROY, this.onDestroy, this);
        this.game.events.on(EVENTS.GAME_SPEED_CHANGED, this.onGameSpeedChanged, this);
        this.onGameSpeedChanged(this.game.speed);
    }
    getByName<T extends Phaser.GameObjects.GameObject>(name: string, source?: Phaser.GameObjects.DisplayList | Phaser.GameObjects.Container): T | null {
        let found: T = null;

        if (source === undefined) {
            source = this.sys.displayList;
        }

        if (typeof source.getByName === "function") {
            const names = name.split("/");

            for (let i = 0; i < names.length; i++) {
                found = source.getByName(names[i]) as T;

                if (found === null) {
                    break;
                }

                (source as any) = found;
            }
        }

        return found;
    }
    protected onGameSpeedChanged(speed: number) {
        this.tweens.timeScale = speed;
    }
    protected onGameSizeChanged() {
        const x = Math.round(this.game.gCenterX - this.game.centerX);
        const y = Math.round(this.game.gCenterY - this.game.centerY);
        this.cameras.main.setSize(this.scale.baseSize.width, this.scale.baseSize.height);
        this.cameras.main.setScroll(x, y);
    }
    protected onDestroy() {
        this.events.off(Phaser.Scenes.Events.DESTROY, this.onDestroy, this);
        this.game.events.off(EVENTS.GAME_SPEED_CHANGED, this.onGameSpeedChanged, this);
        this.sys.events.off(Phaser.Scenes.Events.RESUME, this.onGameSizeChanged, this);
        this.sys.events.off(Phaser.Scenes.Events.WAKE, this.onGameSizeChanged, this);
        this.game.events.off("gamesizechanged", this.onGameSizeChanged, this);
    }
}