import { Scene } from "../Scene";
import { StringToReference } from "./StringToReference";

export type GameObjectsConfig = Phaser.Types.GameObjects.GameObjectConfig
    | Phaser.Types.GameObjects.BitmapText.BitmapTextConfig
    | Phaser.Types.GameObjects.Graphics.Options
    | Phaser.Types.GameObjects.Sprite.SpriteConfig
    | Phaser.Types.GameObjects.Mesh.MeshConfig
    | Phaser.Types.GameObjects.Text.TextConfig | any;
export type GameObjectBuilderConfig = {
    type?: string;
    isReference?: boolean;
    config?: GameObjectsConfig & { name?: string; children?: { [gameObjName: string]: GameObjectBuilderConfig }; portrait?: GameObjectsConfig; landscape?: GameObjectsConfig; };
};

export function Create(scene: Scene, detail: { [gameObjectName: string]: GameObjectBuilderConfig }, parent: Phaser.GameObjects.DisplayList | Phaser.GameObjects.Container) {
    const cloneDetail = ReplaceVariables(scene, Object.assign({}, detail));

    for (const gName in cloneDetail) {
        if (Object.prototype.hasOwnProperty.call(cloneDetail, gName)) {
            const gConf = cloneDetail[gName];
            if (typeof scene.make[gConf.type] === "function" || gConf.isReference === true) {
                if (gConf.config === undefined) {
                    gConf.config = { name: gName };
                }

                if (gConf.config.name === "" || gConf.config.name === undefined) {
                    gConf.config.name = gName;
                }

                const name = gConf.config.name;
                const addTo = gConf.config.add !== false;
                const portrait = gConf.config["portrait-primary"] || {};
                const landscape = gConf.config["landscape-primary"] || {};
                const children = gConf.config.children;

                gConf.config.add = false;
                gConf.config["portrait-primary"] = undefined;
                gConf.config["landscape-primary"] = undefined;
                gConf.config.children = undefined;

                let gObj = null;

                try {
                    if (gConf.isReference) {
                        gObj = new (StringToReference(gConf.type) || Phaser.GameObjects.GameObject)(scene, gConf.config)
                    } else {
                        gObj = scene.make[gConf.type](gConf.config, false);
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    if (gObj !== null) {
                        gObj.name = name;
                        if (addTo && typeof parent.add === "function") {
                            parent.add(gObj);
                            parent.sort("depth");
                        }

                        gConf.config.add = addTo;
                        gConf.config["portrait-primary"] = portrait;
                        gConf.config["landscape-primary"] = landscape;
                        gConf.config.children = children;

                        if (typeof gConf.config.children === "object" && !Array.isArray(gConf.config.children)) {
                            for (const cName in gConf.config.children) {
                                if (Object.prototype.hasOwnProperty.call(gConf.config.children, cName)) {
                                    const child = gConf.config.children[cName];
                                    Create(scene, JSON.parse(`{"${cName}":${JSON.stringify(child)}}`), gObj);
                                    if (typeof gObj.sort === "function") {
                                        gObj.sort("depth");
                                    }
                                }
                            }
                        }
                        gObj.config = gConf;
                    }
                }
            }
        }
    }
    scene.sys.displayList.sort("depth");
}
function ReplaceVariables(scene: Scene, detail: { [gameObjectName: string]: GameObjectBuilderConfig }): { [gameObjectName: string]: GameObjectBuilderConfig } {
    if (scene.game.replacer) {
        detail = (scene.game.replacer.replace({
            replaceable: JSON.stringify(detail)
        }) as any).replaceable as { [gameObjectName: string]: GameObjectBuilderConfig };
    }

    detail = ExecuteValue(detail);

    return detail;
}
function ExecuteValue(data: Record<string, any>): any {
    if (typeof data === "object") {
        if (!Array.isArray(data)) {
            for (const pName in data) {
                if (Object.prototype.hasOwnProperty.call(data, pName)) {
                    if (typeof data[pName] === "string") {
                        if (data[pName].includes("Func(") && data[pName].includes(")EndFunc")) {
                            const func = new Function("return " + data[pName].replace("Func(", "").replace(")EndFunc", ""));
                            data[pName] = func();
                        }
                    } else {
                        data[pName] = ExecuteValue(data[pName]);
                    }
                }
            }
        } else {
            data.forEach((value, index) => {
                if (typeof value === "string") {
                    if (value.includes("Func(") && value.includes(")EndFunc")) {
                        const func = new Function("return " + value.replace("Func(", "").replace(")EndFunc", ""));
                        data[index] = func();
                    }
                } else {
                    value = ExecuteValue(value);
                }
            });
        }
    }

    return data;
}
export function Apply(scene: Scene, detail: { [gameObjectName: string]: GameObjectBuilderConfig }, parent: Phaser.GameObjects.DisplayList | Phaser.GameObjects.Container) {
    const cloneDetail = ReplaceVariables(scene, Object.assign({}, detail));

    for (const gName in cloneDetail) {
        if (Object.prototype.hasOwnProperty.call(cloneDetail, gName)) {
            const gConf = cloneDetail[gName];
            if (gConf.config === undefined) {
                gConf.config = { name: gName };
            }

            if (gConf.config.name === "" || gConf.config.name === undefined) {
                gConf.config.name = gName;
            }

            const gObj = scene.getByName(gConf.config.name, parent);
            if (gObj) {
                const orientationConf = gConf.config[scene.scale.orientation];

                if (typeof orientationConf === "object" && !Array.isArray(orientationConf) && orientationConf !== null) {
                    delete orientationConf.name;
                    for (const p in orientationConf) {
                        if (Object.prototype.hasOwnProperty.call(orientationConf, p)) {
                            const setParam = `set${p.charAt(0).toUpperCase() + p.slice(1)}`;
                            if (typeof gObj[setParam] === "function") {
                                let args = orientationConf[p];
                                if (!Array.isArray(orientationConf[p])) {
                                    args = [args];
                                }
                                gObj[setParam].call(gObj, ...args);
                            } else {
                                gObj[p] = orientationConf[p];
                            }
                        }
                    }

                    //Phaser.GameObjects.BuildGameObject(this.scene, gObj, orientationConf);
                }

                if (typeof gConf.config.children === "object" && !Array.isArray(gConf.config.children)) {
                    for (const cName in gConf.config.children) {
                        if (Object.prototype.hasOwnProperty.call(gConf.config.children, cName)) {
                            const child = gConf.config.children[cName];
                            Apply(scene, JSON.parse(`{"${cName}":${JSON.stringify(child)}}`), gObj as any);
                            if (typeof (gObj as any).sort === "function") {
                                (gObj as any).sort("depth");
                            }
                        }
                    }
                }
            }
        }
    }
    scene.sys.displayList.sort("depth");
}