export enum EVENTS {
    START_CLICKED = "startclicked",
    DATA_RECEIVED = "datareceived",
    STOP_CLICKED = "stopclicked",
    SPIN_STARTED = "spinstarted",
    SPIN_STOPPED = "spinstopped",
    REEL_STARTED = "reelstarted",
    REEL_STOPPED = "reelstopped",
    ROUND_STARTED = "roundstarted",
    ROUND_FINISHED = "roundfinished",
    SHOW_WINS = "showwins",
    SHOW_NEXT_WIN = "shownextwin",
    SHOW_NEXT_WIN_FINISHED = "shownextwinfinished",
    SKIP_WINS = "skipwins",
    WINS_SHOW_FINISHED = "winsshowfinished",
    PLAY_BIG_WIN = "playbigwin",
    BIG_WIN_FINISHED = "bigwinfinished",
    PLAY_MULTIPLIER = "playmultiplier",
    MULTIPLIER_FINISHED = "multiplierfinished",
    SHOW_WIN_LOOPS = "showwinloops",
    SHOW_NEXT_WIN_LOOP = "shownextwinloop",
    SKIP_WIN_LOOPS = "skipwinloops",
    SHOW_NEXT_WIN_LOOP_FINISHED = "shownextwinloop",
    WINS_SHOW_LOOP_FINISHED = "winsshowloopfinished",
    SHOW_EXTRA_SPIN = "showextraspin",
    EXTRA_SPIN_FINISHED = "extraspinfinished",
    DOUBLE_CHANCE_TO_WIN_CHANGED = "doublechancetowinchanged",
    DOUBLE_CHANCE_TO_WIN_REJECTED = "doublechancetowinrejected",
    BUY_FEATURE = "buyfeature",
    BUY_FEATURE_CANCEL = "buyfeaturecancel",
    BUY_FEATURE_REJECTED = "buyfeaturerejected",
    FREE_SPIN_INTRO = "freespinintro",
    FREE_SPIN_INTRO_FINISHED = "freespinintrofinished",
    FREE_SPIN_OUTRO = "freespinoutro",
    FREE_SPIN_OUTRO_FINISHED = "freespinoutrofinished"
}