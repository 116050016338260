import { Reel } from "./Reel";
import { ReelConfig } from "./ReelConfig";
import { REEL_DIRECTION } from "./ReelDirection";

export class SpinReel extends Reel {
    setConfig(config: ReelConfig): void {
        super.setConfig(config);
        this._excess = this._size;
    }
    start(delay = 0): void {
        this.isRunning = true;
        this.isPreStarted = false;
        this.isPostStarted = false;
        if (this.hold) {
            this.isPreStarted = true;
            this.isPostStarted = true;
        }
        this.isLooping = false;
        this._loopCount = 0;
        this.isPreStoppped = false;
        this.isPostStopped = false;
        this.isStoppable = false;

        const startY = this.container.y;
        const startX = this.container.x;
        let stopY = startY + (this._cellHeight * (this.direction === REEL_DIRECTION.TOP_TO_BOTTOM ? 1 : -1));
        let stopX = startX + (this._cellWidth * (this.direction === REEL_DIRECTION.LEFT_TO_RIGHT ? 1 : -1));
        this.stopPos = -1;
        this.currentPos = 0;
        const startTweenConfig = {
            targets: this.container,
            y: stopY,
            x: stopX,
            delay,
            duration: this._startTweenVars.duration,
            ease: this._startTweenVars.ease,
            easeParams: this._startTweenVars.easeParams,
            callbackScope: this,
            onStart: () => {
                this.isPreStarted = true;
                this.container.setPosition(startX, startY);
                this.showReelMask(true);
                this.showExcessSymbols(true);
            },
            onComplete: () => {
                this.startTweens = [];
                this.changeSymbolLocation();

                this.isPostStarted = true;

                let loopTween = this.scene.tweens.add({
                    targets: this.container,
                    y: stopY,
                    x: stopX,
                    duration: this._loopTweenVars.duration,
                    ease: this._loopTweenVars.ease,
                    easeParams: this._loopTweenVars.easeParams,
                    callbackScope: this,
                    onStart: () => {
                        this.container.setPosition(startX, startY);
                        loopTween.setTimeScale(this.speed);
                    },
                    onComplete: () => {
                        this.isLooping = true;
                        this._loopCount++;
                        // decide stop position
                        if (this.isStoppable === true && this.stopPos === -1) {
                            const ttbOrltr = this.direction === REEL_DIRECTION.TOP_TO_BOTTOM || this.direction === REEL_DIRECTION.LEFT_TO_RIGHT;
                            this.currentPos = ttbOrltr ? this._size * 4 : this._size * 3;
                            this.stopPos = ttbOrltr ? this._size * 3 : this._size * 4;
                        }

                        this.changeSymbolLocation();

                        // check it should stop
                        if (this.isStoppable === true && this.currentPos === this.stopPos) {
                            stopY = startY + ((this._cellHeight * this._excess) * (this.direction === REEL_DIRECTION.TOP_TO_BOTTOM ? -1 : 1));
                            stopX = startX + ((this._cellWidth * this._excess) * (this.direction === REEL_DIRECTION.LEFT_TO_RIGHT ? -1 : 1));
                            const stopTweenConfig: Phaser.Types.Tweens.TweenBuilderConfig = {
                                targets: this.container,
                                y: startY,
                                x: startX,
                                duration: this._stopTweenVars.duration,
                                ease: this._stopTweenVars.ease,
                                easeParams: this._startTweenVars.easeParams,
                                callbackScope: this,
                                onStart: () => {
                                    this.isPreStoppped = true;
                                    this.fillSymbols();
                                    this.container.setPosition(stopX, stopY);
                                },
                                onComplete: () => {
                                    this.isPostStopped = true;
                                    this.isRunning = false;
                                    this.showExcessSymbols(false);
                                    this.showReelMask(false);
                                    this.stopTweens = [];
                                }
                            };
                            this.stopTweens.push(this.scene.tweens.add(stopTweenConfig));
                            loopTween = null;
                        } else {
                            loopTween.restart();
                        }
                    }
                });
            }
        };
        this.startTweens.push(this.scene.tweens.add(startTweenConfig));
    }
    stop(): void {
        if (this.hold === true) {
            this.isPreStoppped = true;
            this.isPostStopped = true;
            this.isStoppable = true;
            this.isRunning = false;
        } else {
            this.isStoppable = true;
        }
    }
}