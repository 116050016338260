/// <reference path="global.d.ts" />
import * as Phaser from "phaser";
import { Extend } from "./Extend";
import { SOUND_GROUPS } from "./extends";
// run extend function
Extend();
export { SOUND_GROUPS };
export * as Casino from "./casino";
export * as Plugins from "./plugins";
export * as Utils from "./utils";
export * from "./CreateGame";
export * from "./Events";
export * from "./Game";
export * from "./GameConfig";
export * from "./Level";
export * from "./Scene";
export const VERSION = "1.6.2";
