import { EVENTS } from "../Events";
import { Machine, MACHINE_EVENTS } from "../machine";
import { SlotModes } from "../SlotModes";
import { FreeSpinConfig } from "./FreeSpinConfig";
import { SpinConfig } from "./SpinConfig";
import { SpinnerConfig } from "./SpinnerConfig";

export class Spinner extends Phaser.Events.EventEmitter {
  machine: Machine;
  protected _tempOldMode: SlotModes = 'spin';

  // set default spin config values
  protected _spinConf: SpinConfig = {
    duration: 0,
    breakDuration: 50,
    delay: 50,
    allowBigWin: true,
    playBigWinFirst: true,
    skipWin: true,
    allowReSpin: false,
    allowMultiplier: false,
    playMultiplierFirst: true,
    dataBinder: null
  };
  // set default free spin config values
  protected _freespinConf: FreeSpinConfig = {
    duration: 0,
    breakDuration: 50,
    delay: 50,
    allowBigWin: true,
    playBigWinFirst: true,
    skipWin: true,
    allowReSpin: false,
    allowMultiplier: false,
    playMultiplierFirst: true,
    allowExtraSpin: false,
    dataBinder: null
  };
  // utils
  bind(machine: Machine, initialData: any, config?: SpinnerConfig): this {
    this.unbind();
    this.machine = machine;
    this.bindConfig(config);
    this.addGameListeners();
    this.onDataReceive(initialData, true);
    this.decideNextMove();
    return this;
  }
  unbind(): this {
    if (this.machine) {
      this.removeGameListeners();
      this.machine = null;
    }
    return this;
  }
  bindConfig(config?: SpinnerConfig): this {
    if (config === undefined) { config = {}; }
    if (config.spin === undefined) { config.spin = {}; }
    if (config.freespin === undefined) { config.freespin = {}; }

    // bind spin config
    this._spinConf.duration = Phaser.Utils.Objects.GetFastValue(config.spin, "duration", 1000);
    this._spinConf.breakDuration = Phaser.Utils.Objects.GetFastValue(config.spin, "breakDuration", 50);
    this._spinConf.delay = Phaser.Utils.Objects.GetFastValue(config.spin, "delay", 100);
    this._spinConf.allowBigWin = Phaser.Utils.Objects.GetFastValue(config.spin, "allowBigWin", true);
    this._spinConf.playBigWinFirst = Phaser.Utils.Objects.GetFastValue(config.spin, "playBigWinFirst", true);
    this._spinConf.skipWin = Phaser.Utils.Objects.GetFastValue(config.spin, "skipWin", true);
    this._spinConf.allowReSpin = Phaser.Utils.Objects.GetFastValue(config.spin, "allowReSpin", false);
    this._spinConf.allowMultiplier = Phaser.Utils.Objects.GetFastValue(config.spin, "allowMultiplier", false);
    this._spinConf.playMultiplierFirst = Phaser.Utils.Objects.GetFastValue(config.spin, "playMultiplierFirst", true);
    this._spinConf.allowForceStop = Phaser.Utils.Objects.GetFastValue(config.spin, "allowForceStop", true);
    this._spinConf.dataBinder = Phaser.Utils.Objects.GetFastValue(config.spin, "dataBinder", null);

    this._freespinConf.duration = Phaser.Utils.Objects.GetFastValue(config.freespin, "duration", 1000);
    this._freespinConf.breakDuration = Phaser.Utils.Objects.GetFastValue(config.freespin, "breakDuration", 50);
    this._freespinConf.delay = Phaser.Utils.Objects.GetFastValue(config.freespin, "delay", 100);
    this._freespinConf.allowBigWin = Phaser.Utils.Objects.GetFastValue(config.freespin, "allowBigWin", true);
    this._freespinConf.playBigWinFirst = Phaser.Utils.Objects.GetFastValue(config.freespin, "playBigWinFirst", true);
    this._freespinConf.skipWin = Phaser.Utils.Objects.GetFastValue(config.freespin, "skipWin", true);
    this._freespinConf.allowReSpin = Phaser.Utils.Objects.GetFastValue(config.freespin, "allowReSpin", false);
    this._freespinConf.allowMultiplier = Phaser.Utils.Objects.GetFastValue(config.freespin, "allowMultiplier", false);
    this._freespinConf.playMultiplierFirst = Phaser.Utils.Objects.GetFastValue(config.freespin, "playMultiplierFirst", true);
    this._freespinConf.allowExtraSpin = Phaser.Utils.Objects.GetFastValue(config.freespin, "allowExtraSpin", false);
    this._freespinConf.allowForceStop = Phaser.Utils.Objects.GetFastValue(config.freespin, "allowForceStop", true);
    this._freespinConf.dataBinder = Phaser.Utils.Objects.GetFastValue(config.freespin, "dataBinder", null);

    return this;
  }
  syncMachine(): void {
    this.machine.activeReelSet = this.reelset;
    this.machine.symbols = this.symbols;
    this.machine.refresh();
  }
  decideNextMove(): void {
    // sync machine
    this.syncMachine();
    if (this.oldMode !== this.mode) {
      if (this.oldMode === "spin" && this.mode === "freespin") {
        this.game.events.emit(EVENTS.FREE_SPIN_INTRO, this.freespinRemaining);
        this.game.events.once(EVENTS.FREE_SPIN_INTRO_FINISHED, () => {
          setTimeout(() => {
            this.game.events.emit(EVENTS.ROUND_FINISHED, this.currentBalance);
          }, this.config.breakDuration);
        }, this);
      } else if (this.oldMode === "freespin" && this.mode === "spin") {
        this.game.events.emit(EVENTS.FREE_SPIN_OUTRO, this.winTotal);
        this.game.events.once(EVENTS.FREE_SPIN_OUTRO_FINISHED, () => {
          setTimeout(() => {
            this.game.events.emit(EVENTS.ROUND_FINISHED, this.currentBalance);
          }, this.config.breakDuration);
        }, this);
      }
    } else {
      setTimeout(() => {
        this.game.events.emit(EVENTS.ROUND_FINISHED, this.currentBalance);
      }, this.config.breakDuration);
    }
  }
  // game event listeners
  protected addGameListeners(): this {
    if (this.scene) {
      this.game.events.on(EVENTS.START_CLICKED, this.onSpinClick, this);
      this.game.events.on(EVENTS.DATA_RECEIVED, this.onDataReceive, this);
      this.game.events.on(EVENTS.SPIN_STOPPED, this.onSpinStop, this);
      this.game.events.on(EVENTS.SHOW_WINS, this.onShowWins, this);
      this.game.events.on(EVENTS.SHOW_NEXT_WIN_FINISHED, this.onShowNextWinFinished, this);
      this.game.events.on(EVENTS.WINS_SHOW_FINISHED, this.onWinsShowFinished, this);
      this.game.events.on(EVENTS.ROUND_FINISHED, this.onRoundFinished, this);
      this.game.events.on(EVENTS.SHOW_WIN_LOOPS, this.onShowWinLoops, this);
      this.game.events.on(EVENTS.SHOW_NEXT_WIN_LOOP_FINISHED, this.onShowNextWinLoopFinished, this);
      this.game.events.on(EVENTS.WINS_SHOW_LOOP_FINISHED, this.onWinsShowLoopFinished, this);
      this.game.events.on(EVENTS.EXTRA_SPIN_FINISHED, this.onExtraSpinFinished, this);

      // machine events
      this.machine.on(MACHINE_EVENTS.RUNNING, this.onMachineRunning, this);
    }
    return this;
  }
  protected removeGameListeners(): this {
    if (this.scene) {
      this.game.events.off(EVENTS.ROUND_STARTED, this.onRoundStarted, this);
      this.game.events.off(EVENTS.START_CLICKED, this.onSpinClick, this);
      this.game.events.off(EVENTS.DATA_RECEIVED, this.onDataReceive, this);
      this.game.events.off(EVENTS.STOP_CLICKED, this.onStopClick, this);
      this.game.events.off(EVENTS.SPIN_STOPPED, this.onSpinStop, this);
      this.game.events.off(EVENTS.SHOW_WINS, this.onShowWins, this);
      this.game.events.off(EVENTS.SHOW_NEXT_WIN_FINISHED, this.onShowNextWinFinished, this);
      this.game.events.off(EVENTS.WINS_SHOW_FINISHED, this.onWinsShowFinished, this);
      this.game.events.off(EVENTS.ROUND_FINISHED, this.onRoundFinished, this);
      this.game.events.off(EVENTS.SHOW_WIN_LOOPS, this.onShowWinLoops, this);
      this.game.events.off(EVENTS.SHOW_NEXT_WIN_LOOP_FINISHED, this.onShowNextWinLoopFinished, this);
      this.game.events.off(EVENTS.WINS_SHOW_LOOP_FINISHED, this.onWinsShowLoopFinished, this);
      this.game.events.off(EVENTS.EXTRA_SPIN_FINISHED, this.onExtraSpinFinished, this);

      // machine events
      this.machine.off(MACHINE_EVENTS.RUNNING, this.onMachineRunning, this);
    }
    return this;
  }
  // slot game event listeners
  protected onRoundStarted(): void {
    if (this.hasWin === true && this.config.allowWinLoop === true) {
      this.game.events.emit(EVENTS.SKIP_WIN_LOOPS, this.winLoopIndex, this.wins);
    }
  }
  protected onSpinClick(): void {
    if (this.machine.isRunning === false) {
      if (this.isQuickMode === true) {
        this.machine.start(this.config.duration, 0);
        this.machine.forceStop();
      } else {
        this.game.events.off(EVENTS.STOP_CLICKED, this.onStopClick, this);
        this.game.events.once(EVENTS.STOP_CLICKED, this.onStopClick, this);
        this.machine.start(this.config.duration, this.config.delay);
      }
      this.game.events.emit(EVENTS.ROUND_STARTED, this.betValue, this.currentBalance);
    }
  }
  protected onDataReceive(data: any, isInit = false): void {
    // bind custom datas
    if (typeof this.config.dataBinder === "function") {
      data = this.config.dataBinder.call(this.scene, data);
    }

    this.isInit = isInit;
    this.mode = data.mode || "spin";
    this._tempOldMode = this.mode;
    if (isInit) {
      this.oldMode = this.mode;
    }
    this.symbols = data.symbols;
    this.nextAction = data.action.next || "spin";
    this.currentAction = data.action.current || "spin";
    this.winType = data.win.type || "normal";
    this.winAmount = data.win.amount || 0;
    this.winTotal = data.win.total || 0;
    this.multiplier = data.normal?.multiplier || 1;
    this.freespinExtra = data.freespin.extra || 0;
    this.freespinRemaining = data.freespin.remaining || 0;
    this.freespinMultiplier = data.freespin.multiplier || 1;
    this.wins = data.win.symbols || [];
    this.currentBalance = data.balance.current || 0;
    this.oldBalance = data.balance.previous || 0;
    this.betValue = data.betValue || 0;
    this.reelset = data.reelset || "default";

    if (!isInit) {
      // sync symbols
      if (this.machine.isRunning === true) {
        this.machine.symbols = data.symbols;
        this.machine.stop(this.config.delay);
      } else {
        this.game.events.emit(EVENTS.SPIN_STOPPED);
      }
    }

    this.game.registry.set("response", data);
  }
  protected onStopClick(): void {
    if (this.isQuickMode === false && this.config.allowForceStop === true && this.machine.isRunning === true && this.machine.isForcedStop === false) {
      this.machine.forceStop();
    }
  }
  protected onSpinStop(): void {
    if (this.config.allowMultiplier === true && this.hasMultiplier === true && this.config.playMultiplierFirst === true) {
      this.game.events.emit(EVENTS.PLAY_MULTIPLIER, this.mode === "spin" ? this.multiplier : this.freespinMultiplier);
      this.game.events.once(EVENTS.MULTIPLIER_FINISHED, this.checkWins, this);
    } else {
      this.checkWins();
    }
  }
  protected checkWins(): void {
    // big win allowed and playin big win first
    if (this.config.allowBigWin === true && this.hasBigWin === true && this.config.playBigWinFirst === true) {
      this.game.events.emit(EVENTS.PLAY_BIG_WIN, this.winType);
      this.game.events.once(EVENTS.BIG_WIN_FINISHED, this.onBigWinFinished, this);
    } else if (this.hasWin) {
      // playing wins
      this.startWinShow();
    } else {
      this.decideNextMove();
    }
  }
  protected checkExtraSpin(): void {
    if (this.mode === "freespin" && this.config.allowExtraSpin === true && this.hasExtraSpin === true) {
      this.game.events.emit(EVENTS.SHOW_EXTRA_SPIN, this.freespinExtra);
    } else {
      this.decideNextMove();
    }
  }
  protected startWinShow(): void {
    if (this.hasWin) {
      this.game.events.emit(EVENTS.SHOW_WINS, this.wins);
      if (this.config.skipWin === true) {
        this.scene.input.off("pointerup", this.onSkipWin, this);
        this.scene.input.once("pointerup", this.onSkipWin, this);
      }
    } else {
      this.checkExtraSpin();
    }
  }
  protected onBigWinFinished(): void {
    if (this.config.playBigWinFirst === false) {
      this.checkExtraSpin();
    } else {
      this.startWinShow();
    }
  }
  protected onSkipWin(): void {
    this.game.events.emit(EVENTS.SKIP_WINS, this.winIndex, this.wins);
    this.game.events.emit(EVENTS.WINS_SHOW_FINISHED, this.winTotal, this.wins, this.winIndex);
  }
  protected onShowWins(): void {
    this.winIndex = 0;
  }
  protected onShowNextWinFinished(): void {
    // increase win index
    this.winIndex++;

    if (this.winIndex === this.wins.length) {
      this.game.events.emit(EVENTS.WINS_SHOW_FINISHED, this.winTotal, this.wins, this.winIndex);
    } else {
      this.game.events.emit(EVENTS.SHOW_NEXT_WIN, this.wins[this.winIndex], this.wins, this.winIndex);
    }
  }
  protected onWinsShowFinished(): void {
    if (this.config.allowMultiplier === true && this.hasMultiplier === true && this.config.playMultiplierFirst === false) {
      this.game.events.emit(EVENTS.PLAY_MULTIPLIER, this.mode === "spin" ? this.multiplier : this.freespinMultiplier);
      this.game.events.once(EVENTS.MULTIPLIER_FINISHED, this.onMultiplierFinished, this);
    } else if (this.config.allowBigWin === true && this.hasBigWin === true && this.config.playBigWinFirst === false) {
      this.game.events.emit(EVENTS.PLAY_BIG_WIN, this.winType);
      this.game.events.once(EVENTS.BIG_WIN_FINISHED, this.onBigWinFinished, this);
    } else {
      this.checkExtraSpin();
    }
  }
  protected onMultiplierFinished() {
    if (this.config.allowBigWin === true && this.hasBigWin === true && this.config.playBigWinFirst === false) {
      this.game.events.emit(EVENTS.PLAY_BIG_WIN, this.winType);
      this.game.events.once(EVENTS.BIG_WIN_FINISHED, this.onBigWinFinished, this);
    } else {
      this.checkExtraSpin();
    }
  }
  protected onRoundFinished(): void {
    this.oldMode = this._tempOldMode;
    if (this.mode === "spin" && this.hasWin === true && this.config.allowWinLoop === true) {
      this.game.events.emit(EVENTS.SHOW_WIN_LOOPS, this.wins);
    }
  }
  protected onShowWinLoops(): void {
    this.winLoopIndex = 0;
    this.loopCount = 0;
  }
  protected onShowNextWinLoopFinished(): void {
    // increase win index
    this.winLoopIndex++;

    if (this.winLoopIndex === this.wins.length) {
      this.game.events.emit(EVENTS.WINS_SHOW_LOOP_FINISHED, this.winTotal);
    } else {
      this.game.events.emit(EVENTS.SHOW_NEXT_WIN_LOOP, this.wins[this.winLoopIndex], this.winLoopIndex);
    }
  }
  protected onWinsShowLoopFinished(): void {
    // increase loop count
    this.loopCount++;

    if (this.loopCount < this.config.maxWinLoop) {
      setTimeout(() => {
        this.game.events.emit(EVENTS.SHOW_WIN_LOOPS, this.wins);
      }, this.config.winLoopTimeout);
    }
  }
  protected onExtraSpinFinished(): void {
    this.decideNextMove();
  }
  // machine event listeners
  protected onMachineRunning(isRunning: boolean): void {
    if (this.game) {
      this.game.registry.set("isRunning", isRunning);
      this.game.events.emit(isRunning ? EVENTS.SPIN_STARTED : EVENTS.SPIN_STOPPED);
    }
  }
  // getters and setters
  get scene(): Phaser.Scene | undefined {
    return this.machine?.scene;
  }
  get game(): Phaser.Game {
    return this.scene?.game;
  }
  get config(): SpinConfig & FreeSpinConfig {
    return this[`_${this.oldMode}Conf`] || {};
  }
  get mode(): SlotModes {
    return this.game.registry.get("mode") || "spin";
  }
  set mode(value: SlotModes) {
    this.game.registry.set("mode", value);
  }
  get oldMode(): SlotModes {
    return this.game.registry.get("oldMode") || this.mode;
  }
  set oldMode(value: SlotModes) {
    this.game.registry.set("oldMode", value);
  }
  get symbols(): string[][] {
    return this.game.registry.get("symbols");
  }
  set symbols(value: string[][]) {
    this.game.registry.set("symbols", value);
  }
  get nextAction(): string {
    return this.game.registry.get("nextAction");
  }
  set nextAction(value: string) {
    this.game.registry.set("nextAction", value);
  }
  get currentAction(): string {
    return this.game.registry.get("currentAction");
  }
  set currentAction(value: string) {
    this.game.registry.set("currentAction", value);
  }
  get winType(): string {
    return this.game.registry.get("winType");
  }
  set winType(value: string) {
    this.game.registry.set("winType", value);
  }
  get winTotal(): number {
    return this.game.registry.get("winTotal");
  }
  set winTotal(value: number) {
    this.game.registry.set("winTotal", value);
  }
  get winAmount(): number {
    return this.game.registry.get("winAmount");
  }
  set winAmount(value: number) {
    this.game.registry.set("winAmount", value);
  }
  get multiplier(): number {
    return this.game.registry.get("multiplier");
  }
  set multiplier(value: number) {
    this.game.registry.set("multiplier", value);
  }
  get freespinExtra(): number {
    return this.game.registry.get("freespinExtra");
  }
  set freespinExtra(value: number) {
    this.game.registry.set("freespinExtra", value);
  }
  get freespinRemaining(): number {
    return this.game.registry.get("freespinRemaining");
  }
  set freespinRemaining(value: number) {
    this.game.registry.set("freespinRemaining", value);
  }
  get freespinMultiplier(): number {
    return this.game.registry.get("freespinMultiplier");
  }
  set freespinMultiplier(value: number) {
    this.game.registry.set("freespinMultiplier", value);
  }
  get wins(): any[] {
    return this.game.registry.get("wins");
  }
  set wins(value: any[]) {
    this.game.registry.set("wins", value);
  }
  get hasWin(): boolean {
    return this.wins.length > 0;
  }
  get hasMultiplier(): boolean {
    return this.multiplier > 1 || this.freespinMultiplier > 1;
  }
  get hasBigWin(): boolean {
    return this.winType !== "Regular";
  }
  get hasExtraSpin(): boolean {
    return this.freespinExtra > 0;
  }
  get winIndex(): number {
    return this.game.registry.get("winIndex");
  }
  set winIndex(value: number) {
    this.game.registry.set("winIndex", value);
  }
  get winLoopIndex(): number {
    return this.game.registry.get("winLoopIndex");
  }
  set winLoopIndex(value: number) {
    this.game.registry.set("winLoopIndex", value);
  }
  get loopCount(): number {
    return this.game.registry.get("loopCount");
  }
  set loopCount(value: number) {
    this.game.registry.set("loopCount", value);
  }
  get reelset(): string {
    return this.game.registry.get("reelset") || "default";
  }
  set reelset(value: string) {
    this.game.registry.set("reelset", value);
  }
  get currentBalance(): number {
    return this.game.registry.get("currentBalance");
  }
  set currentBalance(value: number) {
    this.game.registry.set("currentBalance", value);
  }
  get oldBalance(): number {
    return this.game.registry.get("oldBalance");
  }
  set oldBalance(value: number) {
    this.game.registry.set("oldBalance", value);
  }
  get betValue(): number {
    return this.game.registry.get("betValue");
  }
  set betValue(value: number) {
    this.game.registry.set("betValue", value);
  }
  get isInit(): boolean {
    return this.game.registry.get("isInit");
  }
  set isInit(value: boolean) {
    this.game.registry.set("isInit", value);
  }
  get isQuickMode(): boolean {
    return this.game.registry.get('QuickSpin') === true || this.game.registry.get('TurboSpin') === true;
  }
}
