export enum MACHINE_EVENTS {
    RUNNING = "running",
    PRE_START = "prestart",
    POST_START = "poststart",
    LOOPING = "looping",
    PRE_STOP = "prestop",
    POST_STOP = "poststop",
    STOPPABLE = "stoppable",
    DATA_RECEIVED = "datareceived",
    FORCED_STOP = "forcedstop"
}