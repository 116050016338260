import { IsObject } from "./IsObject";

export function DotToObject(source: object): object {
    const result = {};

    // For each object path (property key) in the object
    for (const key in source) {
        // Split path into component parts
        const parts = key.split(".");

        // Create sub-objects along path as needed
        let target = result;
        while (parts.length > 1) {
            const part = parts.shift();
            target = target[part] = target[part] || {};
        }

        // Set value at end of path
        target[parts[0]] = source[key]
    }

    return result;
}