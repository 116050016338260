import { Scene } from "./Scene";
import { Apply, Create, GameObjectBuilderConfig } from "./utils";

export class Level extends Phaser.Events.EventEmitter {
    detail: { [gameObjectName: string]: GameObjectBuilderConfig } = {};
    fileConfig: Phaser.Types.Loader.FileConfig = null;
    protected _isBooted = false;
    constructor(public scene: Scene, detail?: { [gameObjectName: string]: GameObjectBuilderConfig } | Phaser.Types.Loader.FileConfig) {
        super();
        this.setDetail(detail);
    }
    protected onDestroy() {
        this.scene.game.events.off("gamesizechanged", this.onResize, this);
    }
    setDetail(detail?: { [gameObjectName: string]: GameObjectBuilderConfig } | Phaser.Types.Loader.FileConfig) {
        if (
            typeof detail === "object"
            && !Array.isArray(detail)
            && detail !== null
            && detail.key === undefined
            && detail.type === undefined
            && detail.url === undefined
        ) {
            this.detail = detail as { [gameObjectName: string]: GameObjectBuilderConfig };
        } else {
            this.fileConfig = detail as Phaser.Types.Loader.FileConfig;
        }
    }
    boot() {
        if (this._isBooted === false) {
            this.scene.events.once(Phaser.Scenes.Events.DESTROY, this.onDestroy, this);
            this._isBooted = true;
            if (this.fileConfig !== null) {
                const file = new Phaser.Loader.File(this.scene.load, this.fileConfig);
                this.scene.load.addFile(file);
                this.scene.load.once("complete", () => {
                    this.detail = JSON.parse(file.xhrLoader.responseText);
                    this.onLevelDetailDownloadCompleted();
                }, this);
                this.scene.load.start();
            } else {
                this.onLevelDetailDownloadCompleted();
            }
        }
    }
    protected onLevelDetailDownloadCompleted() {
        Create(this.scene, this.detail, this.scene.sys.displayList);
        this.scene.game.events.on("gamesizechanged", this.onResize, this);
        this.onResize();
        this.emit("boot");
    }
    protected onResize(): void {
        Apply(this.scene, this.detail, this.scene.sys.displayList);
    }
    destroy(): void {
        super.destroy();
        this.onDestroy();
    }
}