export const SOUND_GROUPS: { [channel: string]: boolean } = {};

let muteLock;

function addGroup(sound, group) {
    if (!group) {
        group = "fx";
    }
    if (sound) {
        if (typeof SOUND_GROUPS[group] === "undefined") {
            SOUND_GROUPS[group] = true;
        }

        sound.group = group;
        sound.mute = !SOUND_GROUPS[group];
    }
}
export function extendSound() {
    // extend html5 audio add
    const htmlSuperAdd = Phaser.Sound.HTML5AudioSoundManager.prototype.add;
    (Phaser.Sound.HTML5AudioSoundManager.prototype as any).add = function (key, config, group) {
        const sound = htmlSuperAdd.call(this, key, config);
        addGroup(sound, group);

        return sound;
    }

    // extend web audio add
    const webSuperAdd = Phaser.Sound.WebAudioSoundManager.prototype.add;
    (Phaser.Sound.WebAudioSoundManager.prototype as any).add = function (key, config, group) {
        const sound = webSuperAdd.call(this, key, config);
        addGroup(sound, group);

        return sound;
    }

    // extend audio sprite add
    const superAddSprite = Phaser.Sound.BaseSoundManager.prototype.addAudioSprite;
    (Phaser.Sound.BaseSoundManager.prototype as any).addAudioSprite = function (key, config, group) {
        const sound = superAddSprite.call(this, key, config);
        addGroup(sound, group);

        return sound;
    }

    // extend play
    const superPlay = Phaser.Sound.BaseSoundManager.prototype.play;
    (Phaser.Sound.BaseSoundManager.prototype as any).play = function (key, extra, group) {
        const isPlaying = superPlay.call(this, key, extra);
        addGroup(this.sounds[this.sounds.length - 1], group);

        return isPlaying;
    }

    // extend audio sprite play
    const superPlaySprite = Phaser.Sound.BaseSoundManager.prototype.playAudioSprite;
    (Phaser.Sound.BaseSoundManager.prototype as any).playAudioSprite = function (key, spriteName, config, group) {
        const isPlaying = superPlaySprite.call(this, key, spriteName, config);
        addGroup(this.sounds[this.sounds.length - 1], group);

        return isPlaying;
    }

    // extend update
    const superUpdate = (Phaser.Sound.BaseSoundManager.prototype as any).update;
    (Phaser.Sound.BaseSoundManager.prototype as any).update = function (time, delta) {
        this.sounds.forEach(function (sound) {
            if (!sound.pendingRemove) {
                sound.mute = !SOUND_GROUPS[sound.group];
            }
        });

        superUpdate.call(this, time, delta);
    }

    // add new mute group method
    Phaser.Sound.BaseSoundManager.prototype.muteGroup = function (key, value) {
        if (typeof value === "boolean") {
            SOUND_GROUPS[key] = !value;
        }
    }

    Phaser.Sound.BaseSoundManager.prototype.addSoundSettings = function (lock: boolean) {
        const soundManager = this;
        this.muteLock = lock;

        document.addEventListener('visibilitychange', () => {
            if (!document.hidden) {
                setTimeout(() => {
                    soundManager.context.suspend()
                }, 300)
            }
        });

        document.addEventListener('webkitvisibilitychange', () => {
            if ((document as any).webkitVisibilityState !== "hidden") {
                setTimeout(() => {
                    soundManager.context.suspend()
                }, 300)
            }
        });

        window.addEventListener('focus', () => {
            setTimeout(() => {
                soundManager.context.suspend()
            }, 300)
        });

        window.addEventListener('blur', () => {
            soundManager.context.suspend();
        });

        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                setTimeout(() => {
                    soundManager.context.suspend()
                }, 300)
            }
        });

        window.addEventListener('pagehide', () => {
            soundManager.context.suspend();
        });
    }
}