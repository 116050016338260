import i18next, { Callback, CloneOptions, DefaultNamespace, InitOptions, InterpolationOptions, KeyPrefix, Module, Modules, Namespace, Newable, NewableModule, ResourceStore, Services, TFunction, TOptions, i18n } from "i18next";
import { $Dictionary } from "i18next/typescript/helpers";
// TODO i18next-http-backend entegrasyonunu yap

export class I18nPlugin extends Phaser.Plugins.ScenePlugin {
    protected languageChangedBound: any;

    //  Called when the Plugin is booted by the PluginManager.
    //  If you need to reference other systems in the Scene (like the Loader or DisplayList) then set-up those references now, not in the constructor.
    boot(): void {
        const eventEmitter: Phaser.Events.EventEmitter = this.systems.events;

        //  Listening to the following events is entirely optional, although we would recommend cleanly shutting down and destroying at least.
        //  If you don't need any of these events then remove the listeners and the relevant methods too.

        eventEmitter.on(Phaser.Scenes.Events.SHUTDOWN, this.shutdown, this);
        this.languageChangedBound = this.languageChanged.bind(this);
        i18next.on("languageChanged", this.languageChangedBound);
    }

    protected shutdown(): void {
        i18next.off("languageChanged", this.languageChangedBound);
        const eventEmitter: Phaser.Events.EventEmitter = this.systems.events;
        eventEmitter.off(Phaser.Scenes.Events.SHUTDOWN, this.shutdown, this, false);
        this.scene = null;
    }

    protected languageChanged(): void {
        this.recursiveUpdateText(this.scene);
    }

    recursiveUpdateText(obj: any): void {
        if (
            obj instanceof Phaser.GameObjects.Text ||
            obj instanceof Phaser.GameObjects.BitmapText ||
            obj instanceof Phaser.GameObjects.DynamicBitmapText
        ) {
            if ((obj as any)._i18nKey) {
                (obj as any).setText((obj as any)._i18nKey);
            }
            return;
        }
        if (obj instanceof Phaser.GameObjects.Container) {
            obj.list.forEach((child: any) => {
                this.recursiveUpdateText(child);
            });
            return;
        }

        if (obj.children && obj.children.length > 0) {
            obj.children.each((child: any) => {
                this.recursiveUpdateText(child);
            });
        }
    }

    // Expose parameterized t in the i18next interface hierarchy
    t(key: string | string[], options?: TOptions): string {
        return i18next.t(key, options);
    }

    initialize<T>(options: InitOptions<T>, callback?: Callback): Promise<TFunction> {
        if (options) {
            if (options.resources) {
                for (const lng in options.resources) {
                    if (Object.prototype.hasOwnProperty.call(options.resources, lng)) {
                        const element = options.resources[lng];
                        if (typeof options.resources[lng] === "string") {
                            options.resources[lng] = this.scene.game.cache.json.get(options.resources[lng] as any);
                        }
                    }
                }
            }
            return i18next.init(options, callback);
        }
        return i18next.init(callback);
    }

    loadResources(callback?: (err: any) => void): void {
        i18next.loadResources(callback);
    }
    use<T extends Module>(module: T | NewableModule<T> | Newable<T>): i18n {
        return i18next.use(module);
    }

    /**
     * Uses similar args as the t function and returns true if a key exists.
     */
    exists(key: string | string[], options?: TOptions): boolean {
        return i18next.exists(key, options);
    }

    /**
    * Returns a resource data by language.
    */
    getDataByLanguage(lng: string): { [key: string]: { [key: string]: string } } | undefined {
        return i18next.getDataByLanguage(lng);
    }

    /**
    * Returns a t function that defaults to given language or namespace.
    * Both params could be arrays of languages or namespaces and will be treated as fallbacks in that case.
    * On the returned function you can like in the t function override the languages or namespaces by passing them in options or by prepending namespace.
    *
    * Accepts optional keyPrefix that will be automatically applied to returned t function.
    */
    getFixedT<
        Ns extends Namespace | null = DefaultNamespace,
        TKPrefix extends KeyPrefix<ActualNs> = undefined,
        ActualNs extends Namespace = Ns extends null ? DefaultNamespace : Ns,
    >(
        ...args:
            | [lng: string | readonly string[], ns?: Ns, keyPrefix?: TKPrefix]
            | [lng: null, ns: Ns, keyPrefix?: TKPrefix]
    ): TFunction<ActualNs, TKPrefix> {
        return i18next.getFixedT(...args);
    }

    changeLanguage(lng?: string, callback?: Callback): Promise<TFunction> {
        return i18next.changeLanguage(lng, callback);
    }

    /**
    * Loads additional namespaces not defined in init options.
    */
    loadNamespaces(ns: string | readonly string[], callback?: Callback): Promise<void> {
        return i18next.loadNamespaces(ns, callback);
    }

    /**
    * Loads additional languages not defined in init options (preload).
    */
    loadLanguages(lngs: string | readonly string[], callback?: Callback): Promise<void> {
        return i18next.loadLanguages(lngs, callback);
    }

    /**
    * Reloads resources on given state. Optionally you can pass an array of languages and namespaces as params if you don't want to reload all.
    */
    reloadResources(
        lngs?: string | readonly string[],
        ns?: string | readonly string[],
        callback?: () => void,
    ): Promise<void> {
        return i18next.reloadResources(lngs, ns, callback);
    }

    /**
    * Changes the default namespace.
    */
    setDefaultNamespace(ns: string): void {
        i18next.setDefaultNamespace(ns);
    }

    /**
    * Checks if namespace has loaded yet.
    * i.e. used by react-i18next
    */
    hasLoadedNamespace(
        ns: string | readonly string[],
        options?: {
            lng?: string | readonly string[];
            precheck: (
                i18n: i18n,
                loadNotPending: (
                    lng: string | readonly string[],
                    ns: string | readonly string[],
                ) => boolean,
            ) => boolean;
        },
    ): boolean {
        return i18next.hasLoadedNamespace(ns, options);
    }

    /**
    * Returns rtl or ltr depending on languages read direction.
    */
    dir(lng?: string): "ltr" | "rtl" {
        return i18next.dir(lng);
    }

    /**
    * Exposes interpolation.format function added on init.
    */
    format(
        value: any,
        format?: string,
        lng?: string,
        options?: InterpolationOptions & $Dictionary,
    ): string {
        return i18next.format(value, format, lng, options);
    }

    /**
    * Will return a new i18next instance.
    * Please read the options page for details on configuration options.
    * Providing a callback will automatically call init.
    * The callback will be called after all translations were loaded or with an error when failed (in case of using a backend).
    */
    createInstance(options?: InitOptions, callback?: Callback): i18n {
        return i18next.createInstance(options, callback);
    }

    /**
    * Creates a clone of the current instance. Shares store, plugins and initial configuration.
    * Can be used to create an instance sharing storage but being independent on set language or namespaces.
    */
    cloneInstance(options?: CloneOptions, callback?: Callback): i18n {
        return i18next.cloneInstance(options, callback);
    }

    /**
    * Gets one value by given key.
    */
    getResource(
        lng: string,
        ns: string,
        key: string,
        options?: Pick<InitOptions, "keySeparator" | "ignoreJSONStructure">,
    ): any {
        return i18next.getResource(lng, ns, key, options);
    }

    /**
    * Adds one key/value.
    */
    addResource(
        lng: string,
        ns: string,
        key: string,
        value: string,
        options?: { keySeparator?: string; silent?: boolean },
    ): i18n {
        return i18next.addResource(lng, ns, key, value, options);
    }

    /**
    * Adds multiple key/values.
    */
    addResources(lng: string, ns: string, resources: any): i18n {
        return i18next.addResources(lng, ns, resources);
    }

    /**
    * Adds a complete bundle.
    * Setting deep param to true will extend existing translations in that file.
    * Setting overwrite to true it will overwrite existing translations in that file.
    */
    addResourceBundle(
        lng: string,
        ns: string,
        resources: any,
        deep?: boolean,
        overwrite?: boolean,
    ): i18n {
        return i18next.addResourceBundle(lng, ns, resources, deep, overwrite);
    }

    /**
    * Checks if a resource bundle exists.
    */
    hasResourceBundle(lng: string, ns: string): boolean {
        return i18next.hasResourceBundle(lng, ns);
    }

    /**
    * Returns a resource bundle.
    */
    getResourceBundle(lng: string, ns: string): any {
        return i18next.getResourceBundle(lng, ns);
    }

    /**
    * Removes an existing bundle.
    */
    removeResourceBundle(lng: string, ns: string): i18n {
        return i18next.removeResourceBundle(lng, ns);
    }
    // getter and setter
    get modules(): Modules {
        return i18next.modules;
    }

    get services(): Services {
        return i18next.services;
    }

    get store(): ResourceStore {
        return i18next.store;
    }
    /**
     * Is set to the current detected or set language.
     * If you need the primary used language depending on your configuration (supportedLngs, load) you will prefer using i18next.languages[0].
     */
    get language(): string {
        return i18next.language;
    }
    /**
     * Is set to an array of language-codes that will be used it order to lookup the translation value.
     */
    get languages(): readonly string[] {
        return i18next.languages;
    }
    /**
     * Is set to the current resolved language.
     * It can be used as primary used language, for example in a language switcher.
     */
    get resolvedLanguage(): string {
        return i18next.resolvedLanguage;
    }
    /**
     * Current options
     */
    get options(): InitOptions {
        return i18next.options;
    }
    get isInitialized(): boolean {
        return i18next.isInitialized;
    }
}