export class DataToTextPlugin extends Phaser.Plugins.ScenePlugin {
    boot(): void {
        this.pluginManager.registerGameObject("dataToText", this.addDataToText.bind(this), this.makeDataToText.bind(this));
        this.pluginManager.registerGameObject("dataToBitmapText", this.addDataToBitmapText.bind(this), this.makeDataToBitmapText.bind(this));
    }
    addDataToText(x: number, y: number, text: string | string[], style: Phaser.Types.GameObjects.Text.TextStyle, watchKey: string, dataManager: Phaser.Data.DataManager, theInterpolations?: any): Phaser.GameObjects.Text {
        const textObj: Phaser.GameObjects.Text = (this.scene.add.text as any)(x, y, text, style, theInterpolations);

        this.buildTextObj(textObj, watchKey, dataManager);
        this.scene.sys.displayList.add(textObj);

        return textObj;
    }
    makeDataToText(config?: Phaser.Types.GameObjects.Text.TextConfig & { watchKey?: string, dataManager?: Phaser.Data.DataManager, theInterpolations?: any }, addToScene?: boolean): Phaser.GameObjects.Text {
        if (config === undefined) { config = {}; }

        if (addToScene !== undefined) {
            config.add = addToScene;
        }

        const textObj: Phaser.GameObjects.Text = (this.scene.make.text as any)(config, config.add);

        this.buildTextObj(textObj, config.watchKey, config.dataManager);

        return textObj;
    }
    addDataToBitmapText(x: number, y: number, font: string, watchKey: string, dataManager: Phaser.Data.DataManager, text?: string | string[], size?: number, align?: number, theInterpolations?: any): Phaser.GameObjects.BitmapText {
        const textObj: Phaser.GameObjects.BitmapText = (this.scene.add.bitmapText as any)(x, y, font, text, size, align, theInterpolations);

        this.buildTextObj(textObj, watchKey, dataManager);
        this.scene.sys.displayList.add(textObj);

        return textObj;
    }
    makeDataToBitmapText(config?: Phaser.Types.GameObjects.BitmapText.BitmapTextConfig & { watchKey?: string, dataManager?: Phaser.Data.DataManager, align?: number, theInterpolations?: any }, addToScene?: boolean): Phaser.GameObjects.BitmapText {
        if (config === undefined) { config = {}; }

        if (addToScene !== undefined) {
            config.add = addToScene;
        }

        const textObj: Phaser.GameObjects.BitmapText = (this.scene.make.bitmapText as any)(config, config.add);

        this.buildTextObj(textObj, config.watchKey, config.dataManager);

        return textObj;
    }
    protected buildTextObj(textObj: Phaser.GameObjects.Text | Phaser.GameObjects.BitmapText, watchKey: string, dataManager?: Phaser.Data.DataManager): void {
        // set data manager function
        textObj.bindDataManager = (dataManager?: Phaser.Data.DataManager) => {
            const currentWatchKey = textObj.watchText;
            textObj.unBindDataManager();
            textObj.dataManager = dataManager;
            if (currentWatchKey) {
                textObj.watch(currentWatchKey);
            }
        };
        textObj.unBindDataManager = () => {
            if (textObj.dataManager && textObj.watchText) {
                textObj.leave();
                textObj.dataManager = null;
            }
        }
        textObj.watch = (watchKey: string) => {
            textObj.leave();
            textObj.watchText = watchKey;
            if (textObj.dataManager) {
                textObj.dataManager.events.on(Phaser.Data.Events.CHANGE_DATA + "-" + textObj.watchText, textObj.onDataChange, textObj);
                textObj.dataManager.events.on(Phaser.Data.Events.SET_DATA, textObj.onDataSet, textObj);
                let value = textObj.dataManager.get(watchKey) || null;

                if (value) {
                    if (["number", "boolean", "string"].indexOf(typeof value) > -1) {
                        value = [value.toString()];
                    }
                    if (textObj._i18nKey || textObj.interpolations) {
                        textObj.interpolations = value;
                        textObj.setText(textObj._i18nKey);
                    } else {
                        textObj.setText(value);
                    }
                }
            }
        }
        textObj.leave = () => {
            if (textObj.dataManager) {
                textObj.dataManager.events.off(Phaser.Data.Events.CHANGE_DATA + "-" + textObj.watchText, textObj.onDataChange, textObj);
                textObj.dataManager.events.off(Phaser.Data.Events.SET_DATA, textObj.onDataSet, textObj);
            }
            textObj.watchText = null;
        }
        textObj.onDataChange = (parent: any, value: any, previousValue: any) => {
            if (value) {
                if (["number", "boolean", "string"].indexOf(typeof value) > -1) {
                    value = [value.toString()];
                }
                if (textObj._i18nKey || textObj.interpolations) {
                    textObj.interpolations = value;
                    textObj.setText(textObj._i18nKey);
                } else {
                    textObj.setText(value);
                }
            }
        }
        textObj.onDataSet = (parent: any, key: string, value: any) => {
            if (textObj.watchText === key) {
                textObj.onDataChange(parent, value, undefined);
            }
        }

        // set values
        textObj.watch(watchKey);
        textObj.bindDataManager(dataManager || this.scene.data);
        textObj.once(Phaser.GameObjects.Events.DESTROY, () => {
            textObj.unBindDataManager();
            delete textObj.dataManager;
            delete textObj.watchText;
        }, this);
    }
}