import { DropReel, Reel, REEL_DIRECTION, REEL_EVENTS, ReelConfig, ReelRunningType, SpinReel } from "../reel";
import { MachineConfig } from "./MachineConfig";
import { MACHINE_EVENTS } from "./MachineEvents";

export class Machine extends Phaser.GameObjects.Container {
    container: Phaser.GameObjects.Container = null;
    reelSets: { [key: string]: number | number[] | (string | number)[] } = {};
    patterns: { [key: string]: (ReelConfig & { type: ReelRunningType })[][] } = {};
    maskObj: Phaser.GameObjects.Graphics | Phaser.GameObjects.Shape | Phaser.GameObjects.Image = null;
    symbolPool: Phaser.GameObjects.Group;
    symbolAdd: (sym: any, type: string | number, reel: Reel, row: number) => void;
    symbolRelease: (sym: any, type: string | number, reel: Reel, row: number) => void;
    symbolTypes: (string | number)[];

    protected _reels: Reel[] = [];
    protected _maskGameObjectData: Phaser.Display.Masks.BitmapMask | Phaser.Display.Masks.GeometryMask = null;
    protected _reverseOrder = false;
    protected _activeReelSet = "default";
    protected _column = 4;
    protected _row = 3;
    rtl = false;
    protected _cellWidth = 100;
    protected _cellHeight = 100;
    protected _useMask = false;
    protected _maskConfig: {
        type: "bitmap" | "geometry";
        config: Phaser.Types.GameObjects.Graphics.Options & { points?: any[] | Phaser.Geom.Point[]; } & Phaser.Types.GameObjects.Sprite.SpriteConfig;
    };
    protected _isRunning = false;
    protected _isPreStarted = false;
    protected _isPostStarted = false;
    protected _isLooping = false;
    protected _loopCount = 0;
    protected _isStoppable = false;
    protected _isForcedStop = false;
    protected _isDataReceived = false;
    protected _isPreStoppped = false;
    protected _isPostStopped = false;
    protected _stopTimeout = -1;
    protected _startTime = 0;
    protected _duration = 0;
    protected _startDelay = 0;
    protected _startReelIndex = -1;
    protected _stopDelay = 0;
    protected _stopReelIndex = -1;

    constructor(scene: Phaser.Scene, config: MachineConfig) {
        super(scene);
        Phaser.GameObjects.BuildGameObject(scene, this, config);
        this.setConfig(config);
        this.boot();
    }
    resetConfig(): void {
        // reset reel configs
        this.releaseReels();
        this._isForcedStop = false;
        this._isRunning = false;
        this._isStoppable = false;
        this._isPreStarted = false;
        this._isPostStarted = false;
        this._isLooping = false;
        this._loopCount = 0;
        this._isPreStoppped = false;
        this._isPostStopped = false;
        this._isDataReceived = false;
        this.reelSets = {};
        this.patterns = {};
        this._reverseOrder = false;
        this._activeReelSet = "default";
        this._column = 4;
        this._row = 3;
        this._cellWidth = 100;
        this._cellHeight = 100;
        this._useMask = false;
        this._maskConfig = null;

        if (this.container !== null) {
            this.remove(this.container);
            this.container.destroy(true);
            this.container = null;
        }
    }
    setConfig(config: MachineConfig): void {
        // reset all properties
        this.resetConfig();

        // get common properties
        const type = Phaser.Utils.Objects.GetFastValue(config, "type", "spin");
        this._cellWidth = Phaser.Utils.Objects.GetFastValue(config, "cellWidth", 100);
        this._cellHeight = Phaser.Utils.Objects.GetFastValue(config, "cellHeight", 100);
        this.symbolAdd = Phaser.Utils.Objects.GetFastValue(config, "symbolAdd", null);
        this.symbolRelease = Phaser.Utils.Objects.GetFastValue(config, "symbolRelease", null);
        this.symbolTypes = Phaser.Utils.Objects.GetFastValue(config, "symbolTypes", {});
        this.symbolPool = new Phaser.GameObjects.Group(this.scene, Phaser.Utils.Objects.GetFastValue(config, "pooling", {}));

        const symbols = Phaser.Utils.Objects.GetFastValue(config, "symbols", []);
        const direction = Phaser.Utils.Objects.GetFastValue(config, "direction", REEL_DIRECTION.TOP_TO_BOTTOM);
        const shuffle = Phaser.Utils.Objects.GetFastValue(config, "shuffle", false);
        const reelMasks = Phaser.Utils.Objects.GetFastValue(config, "reelMasks", []);
        const showExcess = Phaser.Utils.Objects.GetFastValue(config, "showExcess", false);
        this._reverseOrder = Phaser.Utils.Objects.GetFastValue(config, "reverseOrder", false);

        const startTween = Phaser.Utils.Objects.GetFastValue(config, "startTween", {});
        startTween.duration = Phaser.Utils.Objects.GetFastValue(startTween, "duration", 1000);
        startTween.delay = Phaser.Utils.Objects.GetFastValue(startTween, "delay", 0);
        startTween.ease = Phaser.Utils.Objects.GetFastValue(startTween, "ease", Phaser.Math.Easing.Linear);
        startTween.easeParams = Phaser.Utils.Objects.GetFastValue(startTween, "easeParams", []);

        const loopTween = Phaser.Utils.Objects.GetFastValue(config, "loopTween", {});
        loopTween.duration = Phaser.Utils.Objects.GetFastValue(loopTween, "duration", 100);
        loopTween.ease = Phaser.Utils.Objects.GetFastValue(loopTween, "ease", Phaser.Math.Easing.Linear);
        loopTween.easeParams = Phaser.Utils.Objects.GetFastValue(loopTween, "easeParams", null);

        const stopTween = Phaser.Utils.Objects.GetFastValue(config, "stopTween", {});
        stopTween.duration = Phaser.Utils.Objects.GetFastValue(stopTween, "duration", 1000);
        stopTween.delay = Phaser.Utils.Objects.GetFastValue(stopTween, "delay", 0);
        stopTween.ease = Phaser.Utils.Objects.GetFastValue(stopTween, "ease", Phaser.Math.Easing.Linear);
        stopTween.easeParams = Phaser.Utils.Objects.GetFastValue(stopTween, "easeParams", []);

        // set machine properties
        this.reelSets = Phaser.Utils.Objects.GetFastValue(config, "reelSets", { "default": 50 });
        this._activeReelSet = Phaser.Utils.Objects.GetFastValue(config, "reelSet", "default");
        this._column = Phaser.Utils.Objects.GetFastValue(config, "column", 4);
        this._row = Phaser.Utils.Objects.GetFastValue(config, "row", 3);
        this.rtl = Phaser.Utils.Objects.GetFastValue(config, "rtl", false);
        let useMask = Phaser.Utils.Objects.GetFastValue(config, "useMask", false);
        this._useMask = typeof useMask === "boolean" ? useMask : true;
        if (typeof useMask === "object") {
            const maskType = Phaser.Utils.Objects.GetFastValue(useMask, "type", "geometry");
            const maskConfig = Phaser.Utils.Objects.GetFastValue(useMask, "config", {});
            this._maskConfig = {
                type: maskType,
                config: maskConfig
            };
        }

        // create reel patterns
        let patterns: { [key: string]: (ReelConfig & { type: ReelRunningType })[][] } = Phaser.Utils.Objects.GetFastValue(config, "patterns", undefined);
        if (!patterns) {
            patterns = {};
        }
        const generatedPatterns: { [key: string]: (ReelConfig & { type: ReelRunningType })[][] } = {};

        const reelSetKeys = Object.keys(this.reelSets);

        // fill reel patterns and reel sets
        reelSetKeys.forEach((k) => {
            if (generatedPatterns[k] === undefined) {
                generatedPatterns[k] = [];
            }
            if (!this.reelSets[k]) {
                this.reelSets[k] = 50;
            }
            if (typeof this.reelSets[k] === "number") {
                const count = this.reelSets[k] as number;
                this.reelSets[k] = [];
                (this.reelSets[k] as number[]).length = this._column;
                (this.reelSets[k] as number[]).fill(count);
            }
        });

        const generatedPatternKeys = Object.keys(generatedPatterns);

        generatedPatternKeys.forEach((k) => {
            const generatedPattern = generatedPatterns[k];
            const overWritePattern = patterns[k] ?? {};

            let indexes = 0;
            // fill pattern and set missing properties
            for (let c = 0; c < this._column; c++) {
                let counter = this._row;
                generatedPattern[c] = [];
                const generalProperties: ReelConfig & { type: ReelRunningType } = {
                    column: c,
                    type,
                    size: this._row,
                    cellWidth: this._cellWidth,
                    cellHeight: this._cellHeight,
                    direction,
                    shuffle,
                    showExcess,
                    startTween,
                    loopTween,
                    stopTween,
                    reverseOrder: this._reverseOrder
                };
                const overWriteProperties = overWritePattern[c] ?? [generalProperties];
                overWriteProperties.forEach(overWriteProp => {
                    const injectedProperties = {
                        index: indexes,
                        symbols: symbols[indexes] !== undefined ? symbols[indexes] : [],
                        reelSet: this.reelSets[this._activeReelSet][indexes] !== undefined ? this.reelSets[this._activeReelSet][indexes] : 50,
                        useMask: reelMasks[indexes] !== undefined ? reelMasks[indexes] : false
                    };
                    const reelConfig = { ...generalProperties, ...injectedProperties, ...overWriteProp };
                    if (reelConfig.size > counter) {
                        reelConfig.size = counter;
                    }
                    counter -= reelConfig.size;
                    generatedPattern[c].push(reelConfig);
                    indexes++;
                });
            }

            generatedPatterns[k] = generatedPattern;
        });

        this.patterns = generatedPatterns;
    }
    boot(): void {
        // create reels container
        this.container = this.scene.add.container();
        this.container.name = "Reels";
        this.add(this.container);

        this._reels = [];

        // fill container with reels
        this.fillReels();

        // create machine mask
        this.setMachineMask(this._useMask);
    }
    releaseReel(reel: Reel): void {
        // remove from reels array
        const index = this._reels.indexOf(reel as Reel);
        if (index > -1) {
            Phaser.Utils.Array.RemoveAt(this._reels, index);
        }

        // remove reel from container
        this.container?.remove(reel);

        // release used reel
        reel.destroy(true);
    }
    releaseReels(): void {
        const children = this.container?.getAll() ?? [];
        children.forEach((reel) => {
            this.releaseReel(reel as Reel);
        }, this);

        // clear container and reels array
        this.container?.removeAll();
        this._reels = [];
    }
    releaseSymbol(symbol: any, reel: Reel): void {
        // release used symbol
        if (this.symbolRelease && typeof this.symbolRelease === "function") {
            this.symbolRelease(symbol, symbol.getData("identity"), reel, parseInt(symbol.getData("row")));
        }
        this.symbolPool.killAndHide(symbol);
        this.scene.sys.displayList.remove(symbol);
    }
    getSymbol(sym: string | number, reel: Reel, row: number): any | null {
        const symbol = this.symbolPool.get(0, 0);
        symbol.setActive(true).setVisible(true);
        this.scene.sys.displayList.remove(symbol);

        if (this.symbolAdd && typeof this.symbolAdd === "function") {
            this.symbolAdd(symbol, sym, reel, row);
        }

        return symbol;
    }
    fillReels(): void {
        const symbols = this.orderedSymbols;

        // destroy all reels
        this.releaseReels();

        // create all reels
        const reels = [];
        const len = this.activePattern.length;

        for (let i = 0; i < len; i++) {
            let reelPattern = this.activePattern[i];

            if (!Array.isArray(reelPattern)) {
                reelPattern = [reelPattern];
            }

            let size = 0;
            for (let j = 0; j < reelPattern.length; j++) {
                if (symbols[i]) {
                    if (symbols[i][j] && Array.isArray(symbols[i][j])) {
                        reelPattern[j].symbols = symbols[i][j] as any;
                    } else {
                        reelPattern[j].symbols = symbols[i];
                    }
                }
                // refresh symbols
                let reel = null;
                switch (reelPattern[j].type) {
                    case "drop":
                        reel = new DropReel(this.scene, this, { ...reelPattern[j] });
                        break;
                    default:
                        reel = new SpinReel(this.scene, this, { ...reelPattern[j] });
                        break;
                }

                if (reel) {
                    const isVertical = reel.direction === REEL_DIRECTION.TOP_TO_BOTTOM || reel.direction === REEL_DIRECTION.BOTTOM_TO_TOP;
                    reel.y = this._cellHeight * (isVertical ? size : i);
                    reel.x = this._cellWidth * (isVertical ? i : size);
                    size += reel.size;
                    this.container.add(reel);
                    reels.push(reel);
                }
            }
        }

        // sort reels by index
        reels.sort((a, b) => a.index - b.index);
        if (this.rtl === true) {
            reels.reverse();
        }

        // fill with reel objects
        this._reels = [].concat(...reels);

        // refresh reels by depth
        this.orderReels();
    }
    orderReels() {
        if (this._isRunning === false) {
            const reels = this.container.getAll() as Reel[];
            const len = reels.length;

            for (var i = 0; i < len; i++) {
                reels[i].setDepth(this._reverseOrder === true ? (len - 1) - i : i);
            }
            this.container.sort("depth");
        }
    }
    setMachineMask(useMask: boolean): this {
        const hasMask = this.container.mask !== null;
        this.showMachineMask(false, true);

        // destroy old mask game object
        if (this.maskObj !== null) {
            this.maskObj.destroy(true);
            this.maskObj = null;
            this._maskGameObjectData = null;
        }
        this._useMask = useMask;

        if (this._useMask === true) {
            const wtm = this.container.getWorldTransformMatrix();
            const x = this._maskConfig?.config?.x ?? wtm.tx;
            const y = this._maskConfig?.config?.y ?? wtm.ty;

            if (this._maskConfig?.type === "bitmap") {
                this.maskObj = this.scene.make.image({ ...this._maskConfig.config, x, y }, false);
                this._maskGameObjectData = new Phaser.Display.Masks.BitmapMask(this.scene, this.maskObj);
            }
            else {

                let points = this._maskConfig?.config?.points ?? null;

                const width = (this._cellWidth * this._column) * this.container.scaleX;
                const height = (this._cellHeight * this._row) * this.container.scaleY;

                // fill points if it is undefined
                if (points === null) {
                    points = [
                        { x: 0, y: 0 },
                        { x: width, y: 0 },
                        { x: width, y: height },
                        { x: 0, y: height },
                        { x: 0, y: 0 }
                    ];
                }

                const fillStyle = this._maskConfig?.config?.fillStyle ?? {};
                // set properties if not
                if (typeof fillStyle.color !== "number") {
                    fillStyle.color = 0xffffff;
                }
                if (typeof fillStyle.alpha !== "number") {
                    fillStyle.alpha = 1;
                }

                this.maskObj = this.scene.make.graphics({ ...this._maskConfig, fillStyle }, false);
                this.maskObj.fillPoints(points);
                this.maskObj.setPosition(x, y);
                this._maskGameObjectData = new Phaser.Display.Masks.GeometryMask(this.scene, this.maskObj as Phaser.GameObjects.Graphics);

                /*this.maskObj.visible = true;
                this.scene.sys.displayList.add(this.maskObj);*/
            }
        }

        this.showMachineMask(hasMask);

        return this;
    }
    showMachineMask(value: boolean, hard = false): void {
        if (this._useMask === true && value === true) {
            this.container?.setMask(this._maskGameObjectData);
        } else {
            this.container?.clearMask(hard);
        }
    }
    showExcessSymbols(value: boolean): void {
        this._reels.forEach((reel) => {
            reel.showExcessSymbols(value);
        });
    }
    start(duration = 0, delay = 100): void {
        this.isRunning = true;
        this.isPreStarted = false;
        this.isPostStarted = false;
        this.isLooping = false;
        this.isPreStoppped = false;
        this.isStoppable = false;
        this.isForcedStop = false;
        this.isDataReceived = false;
        this._startTime = Date.now();
        this._duration = duration;
        this.showMachineMask(true);
        this._startReelIndex = -1;
        this._startDelay = delay;

        this.startReels();
    }
    protected startReels() {
        this._startReelIndex++;
        if (this._reels[this._startReelIndex]) {
            this.addRemoveReelEvents();
            if (this._isForcedStop === false) {
                setTimeout(() => {
                    this._reels[this._startReelIndex].once(REEL_EVENTS.PRE_START, this.startReels, this);
                    this._reels[this._startReelIndex].start();
                }, this._startDelay);
            } else {
                this._reels[this._startReelIndex].start();
                this.startReels();
            }
        }
    }
    protected addRemoveReelEvents() {
        // remove listeners
        this._reels[this._startReelIndex].off(REEL_EVENTS.PRE_START, this.onReelsPreStart, this);
        this._reels[this._startReelIndex].off(REEL_EVENTS.POST_START, this.onReelsPostStart, this);
        this._reels[this._startReelIndex].off(REEL_EVENTS.PRE_STOP, this.onReelsPreStop, this);
        this._reels[this._startReelIndex].off(REEL_EVENTS.POST_STOP, this.onReelsPostStop, this);
        this._reels[this._startReelIndex].off(REEL_EVENTS.STOPPABLE, this.onReelsStoppable, this);
        this._reels[this._startReelIndex].off(REEL_EVENTS.LOOPING, this.onReelsLooping, this);
        this._reels[this._startReelIndex].off(REEL_EVENTS.RUNNING, this.onReelsRunning, this);
        this._reels[this._startReelIndex].off(REEL_EVENTS.PRE_START, this.startReels, this);

        // add listeners
        this._reels[this._startReelIndex].once(REEL_EVENTS.PRE_START, this.onReelsPreStart, this);
        this._reels[this._startReelIndex].once(REEL_EVENTS.POST_START, this.onReelsPostStart, this);
        this._reels[this._startReelIndex].once(REEL_EVENTS.PRE_STOP, this.onReelsPreStop, this);
        this._reels[this._startReelIndex].once(REEL_EVENTS.POST_STOP, this.onReelsPostStop, this);
        this._reels[this._startReelIndex].once(REEL_EVENTS.STOPPABLE, this.onReelsStoppable, this);
        this._reels[this._startReelIndex].on(REEL_EVENTS.RUNNING, this.onReelsRunning, this);
        this._reels[this._startReelIndex].on(REEL_EVENTS.LOOPING, this.onReelsLooping, this);
    }
    protected onReelsRunning() {
        const isRunning = this.checkState("isRunning", false);
        if (!isRunning) {
            this.isRunning = false;
            this.showMachineMask(false);
        }
    }
    protected onReelsPreStart() {
        const isPreStarted = this.checkState("isPreStarted");
        if (isPreStarted) {
            this.isPreStarted = true;
        }
    }
    protected onReelsPostStart() {
        const isPostStarted = this.checkState("isPostStarted");
        if (isPostStarted) {
            this.isPostStarted = true;
        }
    }
    protected onReelsPreStop() {
        const isPreStoppped = this.checkState("isPreStoppped");
        if (isPreStoppped) {
            this.isPreStoppped = true;
        }
    }
    protected onReelsPostStop() {
        const isPostStopped = this.checkState("isPostStopped");
        if (isPostStopped) {
            this.isPostStopped = true;
        }
    }
    protected onReelsStoppable() {
        const isStoppable = this.checkState("isStoppable");
        if (isStoppable) {
            this.isStoppable = true;
        }
    }
    protected onReelsLooping(reel: Reel, value: boolean, loopCount: number) {
        let isLooping = true;
        for (let i = 0; i < this._reels.length; i++) {
            const reel = this._reels[i];
            if (reel.isLooping === false) {
                isLooping = false;
                break;
            }
        }

        if (isLooping) {
            this._loopCount = loopCount;
            this.isLooping = true;
        }
    }
    protected checkState(state: string, value = true): boolean {
        let isStateValid = value;
        for (let i = 0; i < this._reels.length; i++) {
            const reel = this._reels[i];
            if (reel[state] === !value) {
                isStateValid = !value;
                break;
            }
        }

        return isStateValid;
    }
    stop(delay = 100): void {
        this.isDataReceived = true;
        this._stopReelIndex = -1;
        this._stopDelay = delay;
        const stopDuration = this._duration - (Date.now() - this._startTime);
        clearTimeout(this._stopTimeout);
        if (stopDuration > 0) {
            this._stopTimeout = setTimeout(() => {
                this.stopReels();
            }, stopDuration);
        } else {
            this.stopReels();
        }
    }
    protected stopReels(): void {
        this._stopReelIndex++;
        if (this._reels[this._stopReelIndex]) {
            if (this._isForcedStop === false) {
                setTimeout(() => {
                    this._reels[this._stopReelIndex].once(REEL_EVENTS.PRE_STOP, this.stopReels, this);
                    this._reels[this._stopReelIndex].stop();
                }, this._stopDelay);
            } else {
                this._reels[this._stopReelIndex].stop();
                this.stopReels();
            }
        }
    }
    forceStop(): void {
        this.isForcedStop = true;
    }
    refresh(): void {
        this._reels.forEach((reel, index) => {
            reel.fillSymbols();
        });
    }
    destroy(fromScene?: boolean): void {
        this.symbolPool.destroy(true, true);
        super.destroy(fromScene);
    }
    // getters and setters
    get reverseOrder(): boolean {
        return this._reverseOrder;
    }
    get symbols(): (string | number)[][] {
        const symbols: (string | number)[][] = [];

        this._reels.forEach((reel) => {
            symbols.push(reel.symbols);
        });
        return symbols;
    }
    set symbols(value: (string | number)[][]) {
        this._reels.forEach((reel, index) => {
            reel.setSymbols(value[index]);
        });
    }
    get orderedSymbols(): (string | number)[][] {
        const symbols: (string | number)[][] = [];
        const reelsByColumn = this.reelsByColumn;
        reelsByColumn.forEach((reels, c) => {
            reels.forEach((reel) => {
                if (!symbols[reel.column]) {
                    symbols[reel.column] = [];
                }
                symbols[reel.column].push(...reel.symbols);
            }, this);
        }, this);

        return symbols;
    }
    set orderedSymbols(value: (string | number)[][]) {
        const reelsByColumn = this.reelsByColumn;
        reelsByColumn.forEach((reels, c) => {
            let start = 0;
            reels.forEach((reel) => {
                reel.symbols = value[c].slice(start, start + reel.size);
                start += reel.size;
            }, this);
        }, this);
    }
    get symbolObjects(): any[][] {
        const symbols: any[][] = [];

        this._reels.forEach((reel) => {
            symbols.push(reel.symbolObjects);
        });
        return symbols;
    }
    get activeReelSet(): string {
        return this._activeReelSet;
    }
    set activeReelSet(value: string) {
        if (value !== this._activeReelSet) {
            this._activeReelSet = value;
            this.fillReels();
        }
    }
    get activeReelSetSymbols(): number | number[] | (string | number)[] {
        return this.reelSets[this._activeReelSet];
    }
    get activePattern(): (ReelConfig & { type: ReelRunningType })[][] {
        return this.patterns[this._activeReelSet];
    }
    get reelsByColumn(): Reel[][] {
        const reels = [];
        this._reels.forEach((reel) => {
            if (!reels[reel.column]) {
                reels[reel.column] = [];
            }
            reels[reel.column].push(reel);
        }, this);

        return reels;
    }
    get isRunning(): boolean {
        return this._isRunning;
    }
    set isRunning(value: boolean) {
        this._isRunning = value;
        this.emit(MACHINE_EVENTS.RUNNING, value);
    }
    get isPreStarted(): boolean {
        return this._isPreStarted;
    }
    set isPreStarted(value: boolean) {
        this._isPreStarted = value;
        this.emit(MACHINE_EVENTS.PRE_START, value);
    }
    get isPostStarted(): boolean {
        return this._isPostStarted;
    }
    set isPostStarted(value: boolean) {
        this._isPostStarted = value;
        this.emit(MACHINE_EVENTS.POST_START, value);
    }
    get isPreStoppped(): boolean {
        return this._isPreStoppped;
    }
    set isPreStoppped(value: boolean) {
        this._isPreStoppped = value;
        this.emit(MACHINE_EVENTS.PRE_STOP, value);
    }
    get isPostStopped(): boolean {
        return this._isPostStopped;
    }
    set isPostStopped(value: boolean) {
        this._isPostStopped = value;
        this.emit(MACHINE_EVENTS.POST_STOP, value);
    }
    get isLooping(): boolean {
        return this._isLooping;
    }
    set isLooping(value: boolean) {
        this._isLooping = value;
        this.emit(MACHINE_EVENTS.LOOPING, this, value, this._loopCount);
    }
    get isForcedStop(): boolean {
        return this._isForcedStop;
    }
    set isForcedStop(value: boolean) {
        this._isForcedStop = value;
        this.emit(MACHINE_EVENTS.FORCED_STOP, value);
    }
    get isStoppable(): boolean {
        return this._isStoppable;
    }
    set isStoppable(value: boolean) {
        this._isStoppable = value;
        this.emit(MACHINE_EVENTS.STOPPABLE, value);
    }
    get isDataReceived(): boolean {
        return this._isDataReceived;
    }
    set isDataReceived(value: boolean) {
        this._isDataReceived = value;
        this.emit(MACHINE_EVENTS.DATA_RECEIVED, value);
    }
}