import { EVENTS } from "../../../Events";
import { Scene } from "../../../Scene";
import { DotToObject, JsonPlaceholderReplacer, MergeDeep, StringToReference } from "../../../utils";
import { ProgressBarConfig, BootConfig } from "../BootConfig";

export class BootScene extends Scene {
    protected _progressBar: Phaser.GameObjects.Graphics = null;
    protected _progressBarText: Phaser.GameObjects.Text = null;

    constructor() {
        super({ key: "boot-scene" });
    }
    preload() {
        this.load.json("boot-config", "/assets/boot-config.json");
    }
    create() {
        // disable context menu
        this.game.input.mouse.disableContextMenu();

        let data: { [key: string]: BootConfig } = this.cache.json.get("boot-config");
        data = (this.game.replacer.replace({
            replaceable: JSON.stringify(data)
        }) as any).replaceable as { [key: string]: BootConfig };

        let mergedConf: BootConfig = DotToObject(data.default || {});
        delete data.default;

        // merge suitable profiles
        for (const pName in data) {
            if (Object.prototype.hasOwnProperty.call(data, pName)) {
                if (new Function("return " + pName)()) {
                    mergedConf = MergeDeep(mergedConf, DotToObject(data[pName]))
                }
            }
        }

        // replace placeholders with values
        if (mergedConf.placeholders) {
            this.game.replacer.addVariableMap(mergedConf.placeholders);
        }
        mergedConf = (this.game.replacer.replace({
            replaceable: JSON.stringify(mergedConf)
        }) as any).replaceable as BootConfig;

        // add scenes
        if (mergedConf.scenes) {
            delete mergedConf.scenes["boot-scene"];
            for (const sName in mergedConf.scenes) {
                if (Object.prototype.hasOwnProperty.call(mergedConf.scenes, sName)) {
                    const sceneConfig: any = mergedConf.scenes[sName];
                    if (!sceneConfig.level) {
                        sceneConfig.level = {};
                    }
                    sceneConfig.level = MergeDeep(mergedConf.levels && mergedConf.levels[sName] || {}, sceneConfig.level);
                    if (Object.prototype.hasOwnProperty.call(sceneConfig, "reference")) {
                        const scene = new (StringToReference(sceneConfig.reference) || Scene)({ key: sName, ...sceneConfig });
                        this.scene.add(sName, scene, false);
                    } else {
                        this.scene.add(sName, sceneConfig, false);
                    }
                }
            }
        }

        this.game.cache.json.add("current-boot-config", mergedConf);

        if (typeof mergedConf.preBootPack !== "undefined" && mergedConf.preBootPack !== false) {
            this.load.reset();
            if (typeof mergedConf.preBootPack !== "string") {
                if (!Array.isArray(mergedConf.preBootPack.files)) {
                    const files: Phaser.Types.Loader.FileConfig[] = [];
                    for (const fName in (mergedConf.preBootPack.files as Object)) {
                        if (Object.prototype.hasOwnProperty.call((mergedConf.preBootPack.files as Object), fName)) {
                            files.push((mergedConf.preBootPack.files as Object)[fName]);
                        }
                    }
                    mergedConf.preBootPack.files = files as any;
                }
            }
            this.load.pack({ key: "pre-boot-pack", url: mergedConf.preBootPack });
            this.load.once("complete", this.downloadWebfonts, this);
            this.load.start();
        } else {
            this.downloadWebfonts();
        }
    }
    protected downloadWebfonts() {
        const mergedConf: BootConfig = this.game.cache.json.get("current-boot-config");

        if (mergedConf.webfonts) {
            this.load.script("webfont", "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js");
            this.load.once("complete", function () {
                globalThis.WebFont.load({
                    ...mergedConf.webfonts,
                    active: this.onWebfontLoad.bind(this)
                });
            }, this);
            this.load.start();
        } else {
            this.onWebfontLoad();
        }
    }
    protected onWebfontLoad(): void {
        // set currency format instance
        if (!this.game.currency) {
            this.game.currency = new Intl.NumberFormat(this.game.registry.get("LanguageCode") || "en-EN", { style: "currency"/*, notation: "compact"*/, currency: this.game.registry.get("CurrencyCode") || "EUR" });
        }

        const mergedConf: BootConfig = this.game.cache.json.get("current-boot-config");

        if (mergedConf.i18n) {
            this.i18n.initialize(mergedConf.i18n);
        }

        if (mergedConf.level === undefined) {
            mergedConf.level = {};
        }

        this.level.setDetail(JSON.parse(JSON.stringify(mergedConf.level)));
        super.create(mergedConf);
    }
    ready(data: BootConfig) {
        // get progress bar config
        const progressBarConf: ProgressBarConfig = Phaser.Utils.Objects.Merge((data.level as any).progressBar.config || {}, {
            width: 1920,
            height: 5,
            radius: 0,
            visible: true,
            x: 0,
            y: 0,
            lineStyle: {
                width: 0,
                color: Phaser.Display.Color.GetColor(255, 255, 255),
                alpha: 1
            },
            fillStyle: {
                color: Phaser.Display.Color.GetColor(252, 3, 78),
                alpha: 1
            },
            gradientStyle: null,
            lineGradientStyle: null
        });
        const width = progressBarConf.width;
        const height = progressBarConf.height;
        const radius = progressBarConf.radius;
        const lineStyle = progressBarConf.lineStyle || null;
        const fillStyle = progressBarConf.fillStyle || null;
        const gradientStyle = progressBarConf.gradientStyle || null;
        const lineGradientStyle = progressBarConf.lineGradientStyle || null;

        // add progress bar
        this._progressBar = this.getByName<Phaser.GameObjects.Graphics>("progressBar");
        if (!this._progressBar) {
            this._progressBar = this.make.graphics(progressBarConf, true);
        }

        // add progress bar text
        this._progressBarText = this.getByName<Phaser.GameObjects.Text>("progressBarText");
        if (!this._progressBarText) {
            this._progressBarText = this.make.text(Phaser.Utils.Objects.Merge((data.level as any).progressBarText.config || {}, {
                visible: true,
                x: 960,
                y: 540,
                origin: {
                    x: 0.5,
                    y: 0.5
                },
                style: {
                    fontSize: 18
                },
                text: "PROGRESS_TEXT",
                interpolations: []
            }), true);
        }

        // add boot pack to queue
        this.load.reset();
        if (typeof data.bootPack !== "undefined" && data.bootPack !== false) {
            if (typeof data.bootPack !== "string") {
                if (!Array.isArray(data.bootPack.files)) {
                    const files: Phaser.Types.Loader.FileConfig[] = [];
                    for (const fName in (data.bootPack.files as Object)) {
                        if (Object.prototype.hasOwnProperty.call((data.bootPack.files as Object), fName)) {
                            files.push((data.bootPack.files as Object)[fName]);
                        }
                    }
                    data.bootPack.files = files as any;
                }
            }
            this.load.pack({ key: "boot-pack", url: data.bootPack });
        }

        // listen loader
        this.load.on("progress", function (value) {
            this._progressBar.clear();
            if (lineStyle !== null) {
                this._progressBar.lineStyle(lineStyle.width, lineStyle.color, lineStyle.alpha);
            }
            if (fillStyle !== null) {
                this._progressBar.fillStyle(fillStyle.color, fillStyle.alpha);
            }
            if (lineGradientStyle !== null) {
                this._progressBar.lineGradientStyle(
                    lineGradientStyle.lineWidth,
                    lineGradientStyle.topLeft,
                    lineGradientStyle.topRight,
                    lineGradientStyle.bottomLeft,
                    lineGradientStyle.bottomRight,
                    lineGradientStyle.alpha
                );
            }
            if (gradientStyle !== null) {
                this._progressBar.fillGradientStyle(
                    gradientStyle.topLeft,
                    gradientStyle.topRight,
                    gradientStyle.bottomLeft,
                    gradientStyle.bottomRight,
                    gradientStyle.alphaTopLeft,
                    gradientStyle.alphaTopRight,
                    gradientStyle.alphaBottomLeft,
                    gradientStyle.alphaBottomRight
                );
            }
            //*gradientStyle ile radius birlikte çalışmıyor
            if (radius) {
                this._progressBar.fillRoundedRect(0, 0, width * value, height, radius);
                this._progressBar.strokeRoundedRect(0, 0, width * value, height, radius);
            } else {
                this._progressBar.fillRect(0, 0, width * value, height);
                this._progressBar.strokeRect(0, 0, width * value, height);
            }
            const progress = Math.round(value * 100);
            this._progressBarText.state = progress;
            this._progressBarText.interpolations = [progress];
        }, this);

        this.load.once("complete", function () {
            let afterPack = false;
            if (typeof data.deferBootPack !== "undefined" && data.deferBootPack !== false) {
                if (typeof data.deferBootPack !== "string") {
                    if (!Array.isArray(data.deferBootPack.files)) {
                        const files: Phaser.Types.Loader.FileConfig[] = [];
                        for (const fName in (data.deferBootPack.files as Object)) {
                            if (Object.prototype.hasOwnProperty.call((data.deferBootPack.files as Object), fName)) {
                                files.push((data.deferBootPack.files as Object)[fName]);
                            }
                        }
                        data.deferBootPack.files = files as any;
                    }
                }

                afterPack = true;
            }
            // run entry point scene if exist
            if (data.entryPoint) {
                if (!Array.isArray(data.entryPoint)) {
                    data.entryPoint = [data.entryPoint];
                }

                data.entryPoint.forEach((sc) => {
                    this.scene.run(sc.toString());
                }, this);

                this.game.isDeferCompleted = false;
                if (afterPack) {
                    this.scene.sendToBack();
                    this.game.events.once(EVENTS.BOOT_FINISHED, () => {
                        // download pack files
                        this.load.reset();
                        this.load.pack({ key: "after-boot-pack", url: data.deferBootPack });
                        this.load.once("complete", () => {
                            this.game.isDeferCompleted = true;
                            this.game.events.emit(EVENTS.DEFER_LOADING_COMPLETED);
                            this.scene.stop();
                            this.scene.remove();
                        }, this);
                        this.load.start();
                    }, this);
                } else {
                    this.game.isDeferCompleted = true;
                    // stop directly
                    this.scene.stop();
                    this.scene.remove();
                }
            }

            this.onEntry(data);

            //this.load.removeAllListeners();
        }, this);

        // start the fire
        this.load.start();
    }
    protected onEntry(config: BootConfig) {
        // you can use this for entry scene
    }
}