export function GenerateReelSet(symbols: any[], min = 10, max = 100): string[] {
    const reelSet = [];
    const count = Phaser.Math.Between(min, max);

    if (!Array.isArray(symbols)) {
        symbols = [symbols];
    }

    for (let i = 0; i < count; i++) {
        let rndSym = Phaser.Utils.Array.GetRandom(symbols);
        if (!Array.isArray(rndSym)) {
            rndSym = [rndSym];
        }

        reelSet.push(...rndSym);
    }

    return reelSet;
}