import { EVENTS } from "./Events";
import { GameConfig } from "./GameConfig";
import * as UI from 'slot-ui';
import { JsonPlaceholderReplacer } from "./utils";

export class Game extends Phaser.Game {
    protected _speed = 1;
    info: UI.Interfaces.IGameBootConfig;
    currency: Intl.NumberFormat;
    replacer: JsonPlaceholderReplacer;
    isDeferCompleted = false;
    centerX = 0;
    centerY = 0;
    gCenterX = 0;
    gCenterY = 0;
    ratio = 0;
    gRatio = 0;

    protected _resizeTimeout = -1;
    protected _resizeFunc = null;
    protected _landscape: { width: number; height: number } = null;
    protected _portrait: { width: number; height: number } = null;

    constructor(config?: GameConfig, info?: UI.Interfaces.IGameBootConfig) {
        super(config);
        if (!config) { config = {}; }
        this.info = info;

        // set game speed
        this.speed = Phaser.Utils.Objects.GetFastValue(config, "speed", 1);

        this.sound.addSoundSettings(this.sound.mute);
        // get browser language
        let browserLang = navigator?.languages && navigator?.languages.length > 0
            ? navigator.languages[0].split("-")[0]
            : navigator.language.split("-")[0];
        if (["tr", "en",].indexOf(browserLang) === -1) {
            browserLang = "en";
        }
        const lang = this.registry.has("LanguageCode") ? this.registry.get("LanguageCode") : browserLang;
        const location = new Intl.Locale(lang).region;
        // replace local variables
        this.replacer = new JsonPlaceholderReplacer({
            delimiterTags: [{ begin: "@{{-", end: "-}}@" }]
        });
        this.replacer.addVariableMap({
            d: this.info.device.isDesktop === true ? "desktop" : this.info.device.isTablet ? "tablet" : "mobile",
            dpr: window.devicePixelRatio,
            w: window.screen.width,
            h: window.screen.height,
            aw: window.screen.availWidth,
            ah: window.screen.availHeight,
            lang,
            loc: location
        });

        // GAME ORIENTATION RESIZE

        // get base width
        const width = Phaser.Utils.Objects.GetFastValue(config, "width", config.scale && config.scale.width ? config.scale.width : 1280);

        // get base height
        const height = Phaser.Utils.Objects.GetFastValue(config, "height", config.scale && config.scale.height ? config.scale.height : 720);

        // get or create landscape config
        this._landscape = Phaser.Utils.Objects.GetFastValue(config, "landscape", {});
        this._landscape.width = Phaser.Utils.Objects.GetFastValue(this._landscape, "width", width);
        this._landscape.height = Phaser.Utils.Objects.GetFastValue(this._landscape, "height", height);

        // get or create landscape config
        this._portrait = Phaser.Utils.Objects.GetFastValue(config, "portrait", {});
        this._portrait.width = Phaser.Utils.Objects.GetFastValue(this._portrait, "width", this._landscape.width);
        this._portrait.height = Phaser.Utils.Objects.GetFastValue(this._portrait, "height", this._landscape.height);

        // register and start resize events
        this._resizeFunc = this.resizeTimeoutFunc.bind(this);
        window.addEventListener("resize", this._resizeFunc);
        // remove events on destroy
        this.events.once("destroy", () => {
            window.removeEventListener("resize", this._resizeFunc);
        });

        this.refreshSize();
    }
    protected resizeTimeoutFunc() {
        clearTimeout(this._resizeTimeout);
        this._resizeTimeout = setTimeout(() => {
            this.refreshSize();
        }, this.scale.resizeInterval + 50);
    }
    refreshSize() {
        const orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
        const orientationConfig: {
            width: number;
            height: number;
        } = orientation === 'landscape' ? this._landscape : this._portrait;

        this.scale.setGameSize(orientationConfig.width, orientationConfig.height);
        this.centerX = parseFloat((this.scale.baseSize.width / 2).toFixed(2));
        this.centerY = parseFloat((this.scale.baseSize.height / 2).toFixed(2));
        this.gCenterX = parseFloat((this.scale.gameSize.width / 2).toFixed(2));
        this.gCenterY = parseFloat((this.scale.gameSize.height / 2).toFixed(2));
        this.ratio = parseFloat(this.scale.baseSize.aspectRatio.toFixed(2));
        this.gRatio = parseFloat(this.scale.gameSize.aspectRatio.toFixed(2));
        this.replacer.addVariableMap({
            centerX: this.centerX,
            centerY: this.centerY,
            gCenterX: this.gCenterX,
            gCenterY: this.gCenterY,
            ratio: this.ratio,
            gRatio: this.gRatio
        });
        this.events.emit("gamesizechanged");
        // see https://codepen.io/yandeu/pen/PLGxxe for safe area
    }
    step(time: number, delta: number): void {
        super.step(time, delta * this.speed);
    }
    headlessStep(time: number, delta: number): void {
        super.headlessStep(time, delta * this.speed);
    }
    set speed(value: number) {
        if (this._speed !== value) {
            const old = this._speed;
            this._speed = value;
            this.sound.setRate(this._speed);
            this.events.emit(EVENTS.GAME_SPEED_CHANGED, this._speed, old);
        }
    }
    get speed(): number {
        return this._speed;
    }
}